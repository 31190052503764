<div>
    <h2>Vincular contador ao cliente</h2>
    <form>
        <mat-form-field appearance="outline">
            <input matInput required placeholder="Apenas numeros" mask='00.000.000/0000-00' name="cnpj"
                [(ngModel)]="cnpjCliente" />
            <mat-label>CNPJ - cliente</mat-label>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <input matInput required placeholder="Apenas numeros" mask='000.000.000-00||00.000.000/0000-00'
                name="cpfcnpj" [(ngModel)]="cpfCnpjContador" />
            <mat-label>CPF/CNPJ - contador</mat-label>
        </mat-form-field>
    </form>
    <div>
        <button mat-raised-button (click)="vincular()" class="btn-confirm">
            SALVAR
        </button>
        <button mat-raised-button (click)="cancel()" class="btn-cancel">
            CANCELAR
        </button>
    </div>
</div>
