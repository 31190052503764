<div class="edit">
  <h2>Cliente</h2>
  <form>
    <mat-form-field *ngIf="cliente.id != ''" appearance="outline">
      <input matInput type="text" name="Id" [(ngModel)]="cliente.id" disabled />
      <mat-label>ID</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input
        matInput
        type="text"
        placeholder="Apenas numeros"
        mask="00.000.000/0000-00"
        name="cnpj"
        [(ngModel)]="cliente.cnpj"
        required
        [disabled]="storage.getUserProfile() == 'SUPORTE'"
      />
      <mat-label>CNPJ</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input
        matInput
        type="text"
        name="nomeFantasia"
        placeholder="Ex: Empresa XYZ"
        [(ngModel)]="cliente.nome"
        required
        [disabled]="storage.getUserProfile() == 'SUPORTE'"
      />
      <mat-label>Nome Fantasia</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input
        matInput
        type="text"
        name="razaoSocial"
        placeholder="Ex: Empresa XYZ LTDA."
        [(ngModel)]="cliente.razao"
        required
        [disabled]="storage.getUserProfile() == 'SUPORTE'"
      />
      <mat-label>Razão Social</mat-label>
    </mat-form-field>

    <!-- <mat-form-field *ngIf="cliente.id != ''" appearance="outline">
            <input matInput type="text" name="email" placeholder="Ex: seuemail@email.com" [(ngModel)]="cliente.email" />
            <mat-label>Email</mat-label>
        </mat-form-field> -->

    <mat-form-field appearance="outline">
      <input
        matInput
        type="text"
        placeholder="Ex: João Almeida"
        name="contato"
        [(ngModel)]="cliente.contato"
        [disabled]="storage.getUserProfile() == 'SUPORTE'"
      />
      <mat-label>Contato</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input
        matInput
        type="text"
        placeholder="Apenas numeros"
        name="telefone"
        mask="(00) 00000-0000||(00) 0000-0000"
        [(ngModel)]="cliente.telefone"
        [disabled]="storage.getUserProfile() == 'SUPORTE'"
      />
      <mat-label>Telefone</mat-label>
    </mat-form-field>

  </form>
  <button
    mat-raised-button
    (click)="
      cliente.id == '' || cliente.id == null ? createCliente() : updateCliente()
    "
    class="btn-confirm"
    [disabled]="!cliente.cnpj || !cliente.nome || !cliente.razao"
    *ngIf="storage.getUserProfile() != 'SUPORTE'"
  >
    Salvar
  </button>

  <button mat-raised-button (click)="onNoClick()" class="btn-cancel">
    Cancelar
  </button>
</div>
