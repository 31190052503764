import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MercadoRoutingModule } from './cliente.routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteFormComponent } from './cliente-form/cliente-form.component';
import { ClienteComponent } from './cliente.component';
import { NgxMaskModule } from 'ngx-mask';
import { MatBadgeModule } from "@angular/material/badge";
import { DatePipe } from "@angular/common";
import { FilterPipeModule } from 'src/app/pipes/filter/filter.module';



@NgModule({
  declarations: [
    ClienteFormComponent,
    ClienteComponent,
  ],
  imports: [
    CommonModule,
    MercadoRoutingModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    NgxMaskModule,
    MatIconModule,
    MatSortModule,
    MatPaginatorModule,
    MatCardModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatBadgeModule,
    FilterPipeModule
  ],
  providers: [DatePipe]
})
export class ClienteModule { }
