<div class="mat-elevation-z4 space-y2">
  <div class="header-container">
    <h1 class="header-title">Contadores</h1>
    <div class="row-container">
      <mat-form-field appearance="outline" class="filter">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Busca de contadores" #input [(ngModel)]="filter">
      </mat-form-field>

      <div class="buttons-admin" *ngIf="storage.getUserProfile() == 'ADMIN'">
        <button mat-raised-button (click)="openContadorForm(null)" class="new">
          Novo contador
        </button>
      </div>
    </div>
  </div>

  <mat-table [dataSource]="dataSource" class="full-width-table" matSort>

    <!-- Nome Contador - Column -->
    <ng-container matColumnDef="nome">
      <mat-header-cell *matHeaderCellDef mat-sort-header>NOME</mat-header-cell>
      <mat-cell *matCellDef="let contador">{{ contador.nome }}</mat-cell>
    </ng-container>

    <!-- Cpf Contador - Column -->
    <ng-container matColumnDef="cpf">
      <mat-header-cell *matHeaderCellDef mat-sort-header>CPF/CNPJ</mat-header-cell>
      <mat-cell *matCellDef="let contador">{{ contador.cpfCnpj.length < 14 ? (contador.cpfCnpj | mask:'000.000.000-00') : (contador.cpfCnpj | mask:'00.000.000/0000-00')}}</mat-cell>
    </ng-container>

    <!-- Contabilidade - Column -->
    <ng-container matColumnDef="contabilidade">
      <mat-header-cell *matHeaderCellDef>CONTABILIDADE</mat-header-cell>
      <mat-cell *matCellDef="let contador">{{ contador.contabilidade }}</mat-cell>
    </ng-container>

    <!-- Email Contador - Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef>EMAIL</mat-header-cell>
      <mat-cell *matCellDef="let contador">{{ contador.email }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="dataCadastro">
      <mat-header-cell *matHeaderCellDef>DATA DE CADASTRO</mat-header-cell>
      <mat-cell *matCellDef="let contador">{{ contador.dataCadastro }}</mat-cell>
    </ng-container>

    <!-- Acoes Contador - Column -->
    <ng-container matColumnDef="acoes" class="acoes">
      <mat-header-cell *matHeaderCellDef>AÇÕES</mat-header-cell>
      <mat-cell *matCellDef="let contador">
        <div class="acoes-admin" *ngIf="storage.getUserProfile() == 'ADMIN' || storage.getUserProfile() == 'SUPORTE'">
          <button color="outline" [matTooltip]="storage.getUserProfile() == 'SUPORTE' ? 'Visualizar contador' : 'Editar contador'" mat-raised-button (click)="openContadorForm(contador.id)">
            <mat-icon>
              edit
            </mat-icon>
          </button>
          <button color="outline" matTooltip="Vincular cliente" mat-raised-button (click)="openBindClient(contador.cpfCnpj, contador.nome)" *ngIf="storage.getUserProfile() != 'SUPORTE'">
            <mat-icon>
              group
            </mat-icon>
          </button>
          <button color="warn" matTooltip="Deletar contador" mat-raised-button (click)="openDeleteConfirmation(contador)" *ngIf="storage.getUserProfile() != 'SUPORTE'">
            <mat-icon>
              delete
            </mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row *matRowDef="let contador; columns: displayColumns;"></mat-row>
  </mat-table>

  <!-- PAGINAÇÃO -->
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Seleciona paginação para contador">
  </mat-paginator>
</div>
