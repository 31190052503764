import { ContadorNewDTO } from './../../models/contador-new.dto';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContadorDTO } from '../../models/contador.dto';
import { ClienteDTO } from 'src/app/models/cliente.dto';
import { ListaClientesContadorDTO } from 'src/app/models/lista-clientes-contador.dto';

const BASE_URL: string = environment.baseUrl
const END_POINT: string = "contador"
@Injectable({
  providedIn: 'root'
})
export class ContadorService {

  constructor(private http: HttpClient) { }

  public findAll(): Observable<ContadorDTO[]> {
    return this.http.get<ContadorDTO[]>(`${BASE_URL}/${END_POINT}`);
  }
  public findById(id: string): Observable<ContadorDTO> {
    return this.http.get<ContadorDTO>(`${BASE_URL}/${END_POINT}/id/${id}`);
  }
  public findByCpfCnpj(cpfCnpj: string): Observable<ContadorDTO> {
    return this.http.get<ContadorDTO>(`${BASE_URL}/${END_POINT}/cpfCnpj/${cpfCnpj}`)
  }
  public findClientesByContador(cpfCnjp: string): Observable<ClienteDTO[]> {
    return this.http.get<ClienteDTO[]>(`${BASE_URL}/${END_POINT}/clientes/${cpfCnjp}`);
  }
  public create(contador: ContadorDTO): Observable<ContadorNewDTO> {
    return this.http.post<ContadorNewDTO>(
      `${BASE_URL}/${END_POINT}`,
      this.fromContadorNewDTO(contador)
    );
  }
  public update(contador: ContadorDTO): Observable<ContadorDTO> {
    return this.http.put<ContadorDTO>(
      `${BASE_URL}/${END_POINT}/id/${contador.id}`,
      contador
    );
  }
  public updateByCpfCnpj(contador: ContadorDTO): Observable<ContadorDTO> {
    return this.http.put<ContadorDTO>(`${BASE_URL}/${END_POINT}/cpfCnpj/${contador.cpfCnpj}`, contador)
  }
  public updateClientesContador(cpfCnpjContador: string, cpfCnpjClientes: ListaClientesContadorDTO): Observable<ContadorDTO>{
    return this.http.put<ContadorDTO>(`${BASE_URL}/${END_POINT}/clientes/${cpfCnpjContador}`, cpfCnpjClientes);
  }
  public delete(id: string): Observable<ContadorDTO> {
    return this.http.delete<ContadorDTO>(`${BASE_URL}/${END_POINT}/id/${id}`);
  }

  fromContadorNewDTO(contadorDTO: ContadorDTO): ContadorNewDTO {
    return new ContadorNewDTO( contadorDTO.nome, contadorDTO.cpfCnpj);
  }
}

