<div class="mat-elevation-z4 space-y2">
  <!-- Ações admin será mostrado apenas com perfil de usuario admin -->
  <div class="header-container">
    <h1 class="header-title">Clientes</h1>
    <div class="row-container">
      <mat-form-field appearance="outline" class="filter">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Busca de clientes" #input [(ngModel)]="filter">
      </mat-form-field>

      <div class="buttons-admin" *ngIf="storage.getUserProfile() == 'ADMIN'">
        <button mat-raised-button (click)="openClienteForm(null)" class="new">
          Novo cliente
        </button>
      </div>
    </div>
  </div>


  <!-- Inicio da Tabela de Clientes -->
  <div class="table-container">

    <!-- 'table-container' Será exibido em dispositivos com telas superior a 768px de largura -->
    <mat-table [dataSource]="dataSource" class="full-width-table table-container" matSort>

      <!-- CHECKBOX -->
      <ng-container matColumnDef="checkbox">
        <mat-header-cell *matHeaderCellDef [ngClass]="'checkbox'" matTooltip="Marcar Todos" [matTooltipPosition]="'above'">
          <mat-checkbox [checked]="isAllChecked()" (change)="setAll($event.checked)" [color]="'primary'" *ngIf="storage.getUserProfile() != 'SUPORTE'"></mat-checkbox>
          <mat-cell *matCellDef="let cliente; let i = index;" [ngClass]="'checkbox'">
            <mat-checkbox [checked]="cliente.checked" (change)="onCheck(this.paginator.pageIndex * this.paginator.pageSize + i)" [color]="'primary'" *ngIf="storage.getUserProfile() != 'SUPORTE'"></mat-checkbox>
          </mat-cell>
        </mat-header-cell>
      </ng-container>

      <!-- NOME DO CLIENTE - COLUMN -->
      <ng-container matColumnDef="nome">
        <mat-header-cell *matHeaderCellDef>NOME FANTASIA</mat-header-cell>
        <mat-cell *matCellDef="let cliente"><strong>{{ cliente.nome }}</strong></mat-cell>
      </ng-container>

      <!-- CNPJ DO CLIENTE - COLUMN -->
      <ng-container matColumnDef="cpfCnpj">
        <mat-header-cell *matHeaderCellDef>CNPJ</mat-header-cell>
        <mat-cell *matCellDef="let cliente">{{ cliente.cnpj | mask: '00.000.000/0000-00'}}</mat-cell>
      </ng-container>

      <!-- TELEFONE DO CLIENTE - COLUMN -->
      <ng-container matColumnDef="telefone">
        <mat-header-cell *matHeaderCellDef>TELEFONE</mat-header-cell>
        <mat-cell *matCellDef="let cliente">{{ (cliente.telefone.length <= 10)?(cliente.telefone | mask: '(00) 0000-0000'):(cliente.telefone | mask: '(00) 00000-0000') }}</mat-cell>
      </ng-container>

      <!-- RESPONSAVEL PELO CLIENTE - COLUMN -->
      <ng-container matColumnDef="responsavel">
        <mat-header-cell *matHeaderCellDef>CONTATO</mat-header-cell>
        <mat-cell *matCellDef="let cliente">
          {{cliente.contato}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dataCadastro" *ngIf="storage.getUserProfile() == 'ADMIN' || storage.getUserProfile() == 'SUPORTE'">
        <mat-header-cell *matHeaderCellDef>DATA DO CADASTRO</mat-header-cell>
        <mat-cell *matCellDef="let cliente">
          {{cliente.dataCadastro}}
        </mat-cell>
      </ng-container>

      <!-- ACOES - COLUMN -->
      <ng-container matColumnDef="acoes" class="acoes">

        <!-- ACOES 'ADMIN' - sera mostrado apenas com perfil de usuario 'ADMIN'-->
        <mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="storage.getUserProfile() == 'ADMIN' || storage.getUserProfile() == 'SUPORTE'">
            AÇÕES
          </ng-container>
          <ng-container *ngIf="storage.getUserProfile() != 'ADMIN' && storage.getUserProfile() != 'SUPORTE'">
            <button mat-raised-button class="solicitacao" (click)="newMultipleSolicitacao()" [disabled]="!shouldEnableSolicitacaoButtom()" matTooltip="Multiplas Solicitações" [matTooltipPosition]="'before'">
              <span [matTooltip]="'Multiplas Solicitações'" [matTooltipDisabled]="shouldEnableSolicitacaoButtom()" [matTooltipPosition]="'above'">
                Solicitar
              </span>
            </button>
          </ng-container>
        </mat-header-cell>
        <mat-cell *matCellDef="let cliente">
          <div class="acoes-admin" *ngIf="storage.getUserProfile() == 'ADMIN' || storage.getUserProfile() == 'SUPORTE'">
            <button mat-raised-button [matTooltip]="storage.getUserProfile() == 'SUPORTE' ? 'Visualizar cliente' : 'Editar cliente'" (click)="openClienteForm(cliente.id)" class="action-button">
              <mat-icon>
                edit
              </mat-icon>
            </button>
            <button mat-raised-button color="warn" matTooltip="Deletar cliente"(click)="openDeleteConfirmation(cliente)" class="action-button" *ngIf="storage.getUserProfile() == 'ADMIN'">
              <mat-icon>
                delete
              </mat-icon>
            </button>
          </div>
          <!-- BOTÃO PARA LISTAR SOLICITACÕES DO CLIENTE -->
          <div class="acoes-contador" *ngIf="storage.getUserProfile() != 'ADMIN' && storage.getUserProfile() != 'SUPORTE'">
            <button mat-raised-button class="solicitacao" routerLink="/solicitacoes/{{cliente.cnpj}}" [disabled]="shouldEnableSolicitacaoButtom()">
              Solicitações
            </button>
            <span *ngIf="cliente.download" matBadge="{{cliente.download}}" matBadgeColor="warn"></span>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let cliente; columns: displayColumns;"></mat-row>

    </mat-table>

    <!-- PAGINAÇÃO -->
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Seleciona paginação para cliente">
    </mat-paginator>

  </div>
  <!-- 'card-container' Será exibido em dispositivos com telas inferiores a 768px de largura -->
  <div class="card-container">
    <mat-card appearance="outlined" *ngFor="let cliente of clientes | filter: filter">
      <mat-card-title-group class="card-cliente">
        <mat-card-title>CLIENTE: {{cliente.nome}}</mat-card-title>
        <mat-card-subtitle>CNPJ: {{cliente.cnpj}}</mat-card-subtitle>
        <div class="acoes-contador" *ngIf="storage.getUserProfile() != 'ADMIN'">
          <button mat-raised-button class="solicitacao" routerLink="/solicitacoes/{{cliente.cnpj}}">
            Solicitações
          </button>
          <span *ngIf="cliente.download" matBadge="{{cliente.download}}" matBadgeColor="warn"></span>
        </div>
      </mat-card-title-group>
      <mat-card-content>
        <div class="acoes" *ngIf="storage.getLocalUser()?.perfilDeUsuario == 'ADMIN'">
          <button mat-raised-button matTooltip="Editar cliente" class="edit">
            <mat-icon>
              edit
            </mat-icon>
          </button>
          <button mat-raised-button color="warn" matTooltip="Deletar cliente" (click)="openDeleteConfirmation(cliente)">
            <mat-icon>
              delete
            </mat-icon>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
