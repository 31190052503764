import { ClienteNewDTO } from './../../models/cliente-new.dto';
import { environment } from './../../../environments/environment';

import { Observable } from 'rxjs';
import { ClienteDTO } from '../../models/cliente.dto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const BASE_URL: string = environment.baseUrl
@Injectable({
  providedIn: 'root'
})
export class ClienteService {


  constructor(private http: HttpClient) { }


  public findAll(): Observable<ClienteDTO[]> {
    return this.http.get<ClienteDTO[]>(`${BASE_URL}/cliente`)
  }
  public findById(id: string): Observable<ClienteDTO> {
    return this.http.get<ClienteDTO>(`${BASE_URL}/cliente/id/${id}`)
  }

  public create(cliente: ClienteDTO): Observable<ClienteDTO> {
    return this.http.post<ClienteNewDTO>(
      `${BASE_URL}/cliente`,
      this.fromNewDTO(cliente)
    )
  }
  public update(cliente: ClienteDTO): Observable<ClienteDTO> {
    return this.http.put<ClienteDTO>(
      `${BASE_URL}/cliente/id/${cliente.id}`,
      cliente
    )
  }
  public updateContadorCliente(cnpjClient: string, cpfCnpjContador: string): Observable<ClienteDTO> {
    return this.http.put<ClienteDTO>(`${BASE_URL}/cliente/${cnpjClient}`,
      {
        cpfCnpj: cpfCnpjContador
      })
  }
  public delete(id: string): Observable<ClienteDTO> {
    return this.http.delete<ClienteDTO>(`${BASE_URL}/cliente/id/${id}`);
  }

  public fromNewDTO(cliente: ClienteDTO): ClienteNewDTO{
    return new ClienteNewDTO(
      cliente.cnpj!,
      cliente.contato!,
      cliente.nome!,
      cliente.razao!,
      cliente.telefone!
    )
  }
  public findByCnpj(cnpj: string): Observable<ClienteDTO> {
    return this.http.get<ClienteDTO>(`${BASE_URL}/cliente/${cnpj}`)
  }
}
