export class ClienteNewDTO{
	cnpj?: string
	contato?: string
	nome?: string
	razao?: string
  telefone?: string

  constructor(cnpj: string, contato: string, nome: string, razao: string, telefone: string){
    this.cnpj = cnpj
    this.contato = contato
    this.nome = nome
    this.razao = razao
    this.telefone = telefone
  }
}
