import { SolicitacaoFormComponent } from './solicitacao-form/solicitacao-form.component';
import { SolicitacaoComponent } from './solicitacao.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: '',
    component: SolicitacaoComponent
  },
  {
    path: ':cnpj',
    component: SolicitacaoComponent
  },
  {
    path: 'edit',
    component: SolicitacaoFormComponent
  },
  {
    path: 'edit/:id',
    component: SolicitacaoFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SolicitacaoRoutingModule { }
