import { ContadorComponent } from './contador.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ContadorFormComponent } from './contador-form/contador-form.component';
import { ContadorAlterarSenhaComponent } from './contador-alterar-senha/contador-alterar-senha.component';

export const routes: Routes = [
  {
    path: '',
    component: ContadorComponent
  },
  {
    path: 'edit',
    component: ContadorFormComponent
  },
  {
    path: 'edit/:id',
    component: ContadorFormComponent
  },
  {
    path: 'alterar-senha',
    component: ContadorAlterarSenhaComponent
  }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })

  export class ContadorRoutingModule { }
