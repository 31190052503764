import { UsuarioService } from './../../../services/domain/usuario.service';
import { AuthService } from './../../../services/auth.service';
import { StorageService } from './../../../services/storage.service';
import { ContadorAlterarSenhaComponent } from '../../modules/contador/contador-alterar-senha/contador-alterar-senha.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public viewWidth = window.screen.width

  constructor(
    public storage: StorageService,
    private authService: AuthService,
    private userService: UsuarioService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {

  }

  logout() {
    this.authService.userAuthenticated = false
  }

  openModal() {
    if (this.storage.getLocalUser()?.perfilDeUsuario != "ADMIN" && this.storage.getLocalUser()?.perfilDeUsuario != 'SUPORTE'){
    const dialogRef = this.dialog.open(
      ContadorAlterarSenhaComponent,
      {
        width: '400px'
      }
    )
  }}



}
