<div class="mat-elevation-z4 space-y2">
  <div class="header-container">
    <h1 class="header-title">Usuários</h1>
    <div class="row-container">
      <mat-form-field appearance="outline" class="filter">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Busca de solicitação" #input [(ngModel)]="filter">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Filtro</mat-label>
        <mat-select (selectionChange)="onChange()" [(value)]="selectedValue">
          <mat-option *ngFor="let option of selectValues" [value]="option.value">
            {{option.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="buttons-admin">
        <button mat-raised-button class="new" (click)="openUsuarioForm(null)">
          Novo usuário
        </button>
      </div>
    </div>
  </div>

  <mat-table [dataSource]="dataSource" class="full-width-table" matSort>
    <!-- Email Usuário - Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>LOGIN/EMAIL</mat-header-cell>
      <mat-cell *matCellDef="let usuario">{{ usuario.email }}</mat-cell>
    </ng-container>
    <!-- Nome Usuário - Column -->
    <ng-container matColumnDef="nome">
      <mat-header-cell *matHeaderCellDef mat-sort-header>NOME</mat-header-cell>
      <mat-cell *matCellDef="let usuario">{{ usuario.nome }}</mat-cell>
    </ng-container>
    <!-- Perfil Usuário - Column -->
    <ng-container matColumnDef="perfilDeUsuario">
      <mat-header-cell *matHeaderCellDef mat-sort-header>TIPO</mat-header-cell>
      <mat-cell *matCellDef="let usuario">{{ usuario.perfilDeUsuario }}</mat-cell>
    </ng-container>
    <!-- ACOES - COLUMN -->
    <ng-container matColumnDef="acoes" class="acoes">
      <mat-header-cell *matHeaderCellDef>AÇÕES</mat-header-cell>
      <mat-cell *matCellDef="let usuario">
        <div class="acoes-admin">
          <button color="outline" [matTooltip]="'Editar usuario'" mat-raised-button (click)="openUsuarioForm(usuario.id)" *ngIf="usuario.perfilDeUsuario == 'SUPORTE' ||storage.getLocalUser()?.email == usuario.email">
            <mat-icon>
              edit
            </mat-icon>
          </button>
          <button color="warn" matTooltip="Deletar usuario" mat-raised-button (click)="openDeleteConfirmation(usuario)" *ngIf="usuario.email != 'ti@sgsistemas.com.br'">
            <mat-icon>
              delete
            </mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row *matRowDef="let usuario; columns: displayColumns;"></mat-row>
  </mat-table>
  <!-- PAGINAÇÃO -->
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Seleciona paginação para contador">
  </mat-paginator>
</div>