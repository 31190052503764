import { environment } from './../../environments/environment';
import { UsuarioService } from './domain/usuario.service';
import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { CredenciaisDTO } from '../models/credenciais.dto';
import { EmailDTO } from '../models/email.dto';
import { LocalUser } from '../models/local_user';

const BASE_URL: string = environment.baseUrl
const LOGIN_URL: string = environment.loginUrl

export interface AuthResponse {
  token:string
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userAuthenticated: boolean = false;
  userRole: string = "";
  loading: boolean = false;


  jwtPayload: JwtPayload = {
    sub: '',
    exp: 0
  }

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private userService: UsuarioService
  ) { }

  authenticate(credencial: CredenciaisDTO) {
    return this.http.post<AuthResponse>(`${LOGIN_URL}/login`,
      credencial,
      {
        observe: 'response'
      })
  }

  resetPassword(email: EmailDTO) {
    return this.http.post(`${BASE_URL}/auth/forgot`,
      email,
      {
        observe: 'response',
        responseType: 'text'
      })
  }

  async successfulLogin(authorizationValue: string) {

    let tok = authorizationValue;
    this.jwtPayload = jwt_decode(tok);
    
    let user: LocalUser = {
      token: tok,
      email: this.jwtPayload.sub,
      cpfCnpj: '',
      nome: '',
      perfilDeUsuario: null!,
      usuarioLogado: 'TRUE'
    };
    this.userService.findUserByEmail(user.email!).subscribe(
      res => {
        user.cpfCnpj = res.cpfCnpj
        user.nome = res.nome
        user.perfilDeUsuario = res.perfilDeUsuario
        this.storage.setLocalUser(user);
        this.userRole = res.perfilDeUsuario
      }
    )
  }
  logout() {
    this.storage.setLocalUser(null);
  }

  refreshToken() {
    const localUser = this.storage.getLocalUser();
    const token = localUser?.token;
    if(token != null) {
      return this.http.get<AuthResponse>(`${BASE_URL}/auth/refresh_token`,
      {
        observe: 'response'
      });
    }
    return;
  }
}
