import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsuarioDTO } from 'src/app/models/usuario.dto';
import { UsuarioNewDTO } from 'src/app/models/user-new.dto';

const BASE_URL: string = environment.baseUrl
const END_POINT: string = "usuario"
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(
    private http: HttpClient
  ) { }

  findUserByEmail(email: string): Observable<UsuarioDTO> {
    return this.http.get<UsuarioDTO>(`${BASE_URL}/${END_POINT}/${email}`)
  }

  findAll(): Observable<[UsuarioDTO]> {
    return this.http.get<[UsuarioDTO]>(`${BASE_URL}/${END_POINT}`)
  }

  findAllByPerfilDeUsuario(perfilDeUsuario: string): Observable<[UsuarioDTO]> {
    return this.http.get<[UsuarioDTO]>(`${BASE_URL}/${END_POINT}/perfil/${perfilDeUsuario}`)
  }

  findById(id: string): Observable<UsuarioDTO> {
    return this.http.get<UsuarioDTO>(`${BASE_URL}/${END_POINT}/id/${id}`)
  }

  public create(usuario: UsuarioNewDTO): Observable<UsuarioNewDTO> {
    console.log(`${BASE_URL}/${END_POINT}`,usuario)
    return this.http.post<UsuarioNewDTO>(
      `${BASE_URL}/${END_POINT}`,
      usuario
    );
  }

  public delete(id: string): Observable<UsuarioDTO> {
    return this.http.delete<UsuarioDTO>(`${BASE_URL}/${END_POINT}/id/${id}`)
  }

  public update(usuario: UsuarioNewDTO): Observable<UsuarioDTO> {
    return this.http.put<UsuarioDTO>(`${BASE_URL}/${END_POINT}/id/${usuario.id}`, usuario)
  }
}
