import { ContadorDTO } from 'src/app/models/contador.dto';
import { UtilService } from 'src/app/services/util.service';
import { AlertModalComponent } from './../../modals/alert-modal/alert.modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ContadorService } from 'src/app/services/domain/contador.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredenciaisDTO } from 'src/app/models/credenciais.dto';
import { EmailDTO } from 'src/app/models/email.dto';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public hide: string = 'password'
  public forgotPassword: boolean = false;
  public registered: boolean = true;
  public enviando: boolean = false;
  public _lembrarMe: boolean;
  public showModalCookie: boolean;

  credenciais: CredenciaisDTO = {
    email: '',
    senha: ''
  };
  email: EmailDTO = {
    email: ''
  }
  contador: ContadorDTO = {
    nome: '',
    cpfCnpj: '',
    contabilidade: '',
    email: '',
    senha: '',
    perfilDeUsuario: ''
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private contadorService: ContadorService,
    private dialog: MatDialog,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.verifyCookie();
    this.getLembrarSenha();
    this.authService.logout()
  }

  setLembrarSenha() {
    if (this.credenciais.email && this.credenciais.senha) {
      if (this._lembrarMe == false) {


        let lembrarSenha = [{ email: this.credenciais.email, senha: this.credenciais.senha }];

        localStorage.setItem("LEMBRAR_ME", JSON.stringify(lembrarSenha));
        this._lembrarMe = true;
      } else {
        localStorage.removeItem("LEMBRAR_ME");
      }
    } else {
      this._lembrarMe = false;
      this.openModal("Para habilitar a função de 'Lembrar senha' preecha os campos acima.");
    }
  }

  getLembrarSenha() {
    let localstorage = JSON.parse(localStorage.getItem('LEMBRAR_ME') || '{}');

    if (localstorage[0] != null) {
      this._lembrarMe = true;
      this.credenciais.email = localstorage[0].email;
      this.credenciais.senha = localstorage[0].senha;
    } else {
      this._lembrarMe = false;
    }

  }

  async login() {
    await this.authService.authenticate(this.credenciais)
      .subscribe(
        response => {
          this.authService.successfulLogin(response.body?.token!);
          this.utilService.showMessage("Logado com sucesso!", 'snack-bar-success', null, "bottom")
          this.authService.userAuthenticated = true
          this.router.navigate(['/home']);

        },
        e => this.openModal("Dados de USUÁRIO ou SENHA incorretos! Por favor, tente novamente.")
      )
  }
  resetPassword() {
    this.enviando = true
    this.email.email = this.credenciais.email;
    this.authService.resetPassword(this.email)
      .subscribe(
        response => {
          this.utilService.showMessage("Email enviado. Faça login com a senha gerada!", 'snack-bar-success', null, "top")
          this.forgot()
          this.enviando = false;
        },
        e => {
          this.enviando = false;
          this.utilService.showMessage("Não foi possivel enviar o email. Verifique o email informado e tente novamente", 'snack-bar-error', null, "top")
        }
      )
  }
  findContadorByCpfCnpj(cpfCnpj: string) {
    this.contadorService.findByCpfCnpj(cpfCnpj).subscribe(
      response => {
        this.contador.id = response.id;
        this.contador.nome = response.nome;
        this.contador.contabilidade = response.contabilidade;
        this.contador.email = response.email;
        this.contador.senha = '';
      },
      e => {
        this.onClickSignUp();
        this.openModal("Não foi localizado o cadastro do CPF/CNPJ: "
        + (cpfCnpj.length == 11
        ? this.returnCpfCnpjMask(cpfCnpj)
        : cpfCnpj.length == 14
        ? this.returnCpfCnpjMask(cpfCnpj)
        : cpfCnpj)
        + ". Entre em contato com a SG Sistemas para realizar o pré-cadastro.");
      }
    )
  }

  openModal(mensagem: string) {
    const dialogRef = this.dialog.open(
      AlertModalComponent,
      {
        width: (this.screen() > 900) ? '30%' : '60%',
        data: mensagem
      }
    );
  }
  forgot() {
    this.forgotPassword = (this.forgotPassword) ? false : true

  }
  passwordVisibility(): void {
    this.hide = (this.hide == "password") ? "text" : "password"
  }
  onClickSignUp(): void {
    this.clearForm();
    this.registered = (this.registered) ? false : true
  }

  async signUp() {
    if (!this.validateForm()) {
      this.utilService.showMessage("Preencha todos os campos solicitados para finalizar o cadastro.", 'snack-bar-error', 5000, "top");
      return;
    }

    this.contadorService.findByCpfCnpj(this.contador.cpfCnpj).subscribe(
      res => {
        this.contadorService.updateByCpfCnpj(this.contador).subscribe(
          res => {
            this.utilService.showMessage("Cadastrado com sucesso, faça login", 'snack-bar-success', null, "top");
            this.onClickSignUp();
          },
          e => {
            this.openModal(e.error.message)
          }
        )
      }, e => {
        this.openModal(e.error.message)
      }
    )
  }

  validateForm(): boolean {
    for (let key in this.contador) {
      if (this.contador[key as keyof ContadorDTO] == '' && key != 'perfilDeUsuario') {
        return false;
      }
    }
    return true;
  }

  clearForm() {
    this.contador.cpfCnpj = '';
    this.contador.nome = '';
    this.contador.contabilidade = '';
    this.contador.email = '';
    this.contador.senha = '';
  }

  screen(): number {
    return window.screen.width
  }

  public purecookieDismiss() {
    this.setCookie("termos-e-politicas", true, 7);
    this.verifyCookie();
  }

  private setCookie(e:string , o:boolean, i:number) {
    let t = "";
    if (i) {
      const n = new Date;
      n.setTime(n.getTime() + 24 * i * 60 * 60 * 1e3), t = "; expires=" + n.toUTCString();
    }

    document.cookie = e + "=" + (o || "") + t + "; path=/";
  }

  private getCookie(e:string) {
    for (let o = e + "=", i = document.cookie.split(";"), t = 0; t < i.length; t++) {
      for (let n = i[t]; " " == n.charAt(0);) {
        n = n.substring(1, n.length);
      }
      if (0 == i[t].indexOf(o)) return i[t].substring(o.length, i[t].length);
    }
    return null;
  }

  private verifyCookie() {
    if (this.getCookie("termos-e-politicas")) {
      this.showModalCookie = false;
    } else {
      this.showModalCookie = true;
    }
  }

  returnCpfCnpjMask(cpfCnpj: string) : string {
    if(cpfCnpj.length == 11) {
      return(cpfCnpj.substring(0,3) + '.' + cpfCnpj.substring(3,6) + '.' + cpfCnpj.substring(6,9) + '-' + cpfCnpj.substring(9,11))
    }
    if(cpfCnpj.length == 14) {
      return(cpfCnpj.substring(0,2) + '.' + cpfCnpj.substring(2,5) + '.' + cpfCnpj.substring(5,8) + '/' + cpfCnpj.substring(8,12) + '-' +cpfCnpj.substring(12,14))
    }
    else {
      return('CPF/CNPJ inválido')
    }
  }
}
