import { EmailParametro } from './../models/email-parametro.dto';
import { ParametroGeral } from './../models/parametro-geral.dto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const BASE_URL: string = environment.baseUrl

@Injectable({
  providedIn: 'root'
})

export class ParametrosService {

  constructor(private http: HttpClient) { }

  public findParametrosGerais(): Observable<ParametroGeral[]>{
    return this.http.get<ParametroGeral[]>(`${BASE_URL}/parametro-geral`);
  }
  public findParametroById(id: number): Observable<ParametroGeral>{
    return this.http.get<ParametroGeral>(`${BASE_URL}/parametro-geral/${id}`);
  }
  public findEmailParam(): Observable<EmailParametro>{
    return this.http.get<EmailParametro>(`${BASE_URL}/parametro-email`);
  }
  public updateParametrosGerais(parametros: ParametroGeral[]): Observable<ParametroGeral[]>{
    return this.http.put<ParametroGeral[]>(`${BASE_URL}/parametro-geral`, parametros);
  }
  public updateEmailParam(emailParam: EmailParametro): Observable<EmailParametro>{
    return this.http.put<EmailParametro>(`${BASE_URL}/parametro-email`, emailParam);
  }
}
