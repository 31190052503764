import { StorageService } from './../services/storage.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, HttpHeaders, HttpContext } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

const BASE_URL: string = environment.baseUrl;
@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    constructor(private storage: StorageService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let localUser = this.storage.getLocalUser();
        let N = BASE_URL.length;
        let requestToAPI = req.url.substring(0, N) == BASE_URL;

        if (localUser && requestToAPI) {
            var httpHeaders = new HttpHeaders(
                {
                    'Authorization':'Bearer ' + localUser.token,
                    'Content-Type': 'application/json'
                }
            )
            const authReq = req.clone(
                { headers: httpHeaders})
            return next.handle(authReq);

        } else {
            return next.handle(req);

        }
    }

}
export const AuthInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
};