import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsuarioNewDTO } from 'src/app/models/user-new.dto';
import { UsuarioDTO } from 'src/app/models/usuario.dto';
import { UsuarioService } from 'src/app/services/domain/usuario.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.scss']
})
export class UsuarioFormComponent implements OnInit {

  selectedValue: number;
  selectValues = [
    { value: 1, viewValue: 'ADMIN' },
    // { value: 2, viewValue: 'CONTADOR' },
    // { value: 3, viewValue: 'CLINTE' },
    { value: 4, viewValue: 'SUPORTE' }
  ]

  public usuario: UsuarioDTO = {
    id: '',
    cpfCnpj: '',
    senha: '',
    nome: '',
    email: '',
    perfilDeUsuario: '',
  }

  constructor(
    public storage: StorageService,
    private utilService: UtilService,
    private usuarioService: UsuarioService,
    private dialogRef: MatDialogRef<UsuarioFormComponent>,
    @Inject(MAT_DIALOG_DATA) public id: string,
  ) { }

  ngOnInit(): void {
    this.findById(this.id)
  }

  createUsuario() {
    let email = this.usuario.email.split('@')
    if (!email[1] || email[1].toLocaleLowerCase() != 'sgsistemas.com.br') {
      this.utilService.showMessage('E-mail utilizado não é de domínio da SG!', 'snack-bar-error', 5000, 'bottom')
      return
    } else {
      let newUser: UsuarioNewDTO = {
        nome: this.usuario.nome,
        email: this.usuario.email,
        senha: this.usuario.senha,
        perfilUsuario: 0
      }
      if (this.selectedValue == 1) {
        newUser.perfilUsuario = 1
      } else if (this.selectedValue == 4) {
        newUser.perfilUsuario = 4
      }
      this.usuarioService.create(newUser)
        .subscribe(
          response => {
            this.utilService.showMessage('usuário cadastrado com sucesso', 'snack-bar-success', null, 'bottom')
            this.cancel()
          },
          e => this.utilService.showMessage(e.error.message, 'snack-bar-error', 5000, 'bottom')
        )
    }
  }

  updateUsuario() {
    let email = this.usuario.email.split('@')
    if (!email[1] || email[1].toLocaleLowerCase() != 'sgsistemas.com.br') {
      this.utilService.showMessage('E-mail utilizado não é de domínio da SG!', 'snack-bar-error', 5000, 'bottom')
      return
    } else {
      let newUser: UsuarioNewDTO = {
        id: this.usuario.id,
        nome: this.usuario.nome,
        email: this.usuario.email,
        senha: this.usuario.senha,
        perfilUsuario: 0
      }
      if (this.selectedValue == 1) {
        newUser.perfilUsuario = 1
      } else if (this.selectedValue == 4) {
        newUser.perfilUsuario = 4
      }
      this.usuarioService.update(newUser)
        .subscribe(
          response => {
            this.utilService.showMessage("usuário atualizado com sucesso", 'snack-bar-success', null, "bottom");
            this.cancel();
          },
          e => {
            this.utilService.showMessage(e.error.message, 'snack-bar-error', 5000, "bottom")
          }
        )
    }

  }

  findById(id: string) {
    if (this.id != null) {
      this.usuarioService.findById(id!).subscribe(
        usuario => {
          this.usuario = usuario
          if (usuario.perfilDeUsuario == 'ADMIN') {
            this.selectedValue = 1
          } else if (usuario.perfilDeUsuario == 'SUPORTE') {
            this.selectedValue = 4
          }
        }
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  onChange() {
    this.usuario.perfilDeUsuario = this.selectedValue.toString()
  }

  verifyDisable() {
    if (!this.usuario.id) {
      if (this.usuario.email && this.usuario.nome && this.usuario.perfilDeUsuario && this.usuario.senha) {
        return false
      }
      return true
    } else {
      if (this.usuario.email && this.usuario.nome && this.usuario.perfilDeUsuario) {
        return false
      }
      return true
    }
  }

}
