<div class="container">
  <h1 id="title">MÚLTIPLAS SOLICITAÇÕES</h1>
  <h4 id="subtitle">Defina o período para o qual quer solicitar os XML's dos clientes abaixo:</h4>
  <mat-form-field appearance="outline">
    <mat-label>Clientes</mat-label>
    <textarea matInput placeholder="Apenas numeros" [rows]="rowCalculation()" disabled
      *ngIf="!usuarioMaster">{{clienteString}}</textarea>
    <textarea (change)="validaCpfCnpjSolicitante()" matInput placeholder="Apenas numeros" [rows]="rowCalculation()"
      *ngIf="usuarioMaster">{{clienteString}}</textarea>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Periodo Desejado</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate" required>
      <input matStartDate formControlName="start" placeholder="Data Inicial" required>
      <input matEndDate formControlName="end" placeholder="Data Final" required>
    </mat-date-range-input>
    <mat-hint style="font-size: 12px;">Máximo 31 dias</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <button mat-raised-button class="btn-confirm" (click)="createMultiple(newSolicitacaoMultiple)">
    ENVIAR
  </button>
  <button mat-raised-button class="btn-cancel" (click)="onNoClick()">
    CANCELAR
  </button>
</div>
