import { UtilService } from './../../../services/util.service';
import { AuthService } from 'src/app/services/auth.service';
import { CredenciaisDTO } from './../../../models/credenciais.dto';
import { DialogData } from './../../../models/dialog.data.dto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-delete-comfirmation',
  templateUrl: './delete-comfirmation.component.html',
  styleUrls: ['./delete-comfirmation.component.scss']
})
export class DeleteComfirmationComponent implements OnInit {

  hide: string = 'password';
  credencial: CredenciaisDTO = {
    email: '',
    senha: ''
  };

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    public dialogRef: MatDialogRef<DeleteComfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
  }
  login() {
    this.authService.authenticate(this.credencial).subscribe(
      res => {
        this.data.confirmed = true;
        this.onNoClick();
      },
      error => {
        this.data.confirmed = false;
        this.utilService.showMessage("Falha na autenticação Não Foi possivel fazer a exclusão", 'snack-bar-error', 5000, "top");
      }
    )

  }
  passwordVisibility() {
    this.hide = (this.hide == 'password') ? 'text' : 'password'
  }
  onNoClick() {
    this.dialogRef.close(this.data.confirmed);
  }
}
