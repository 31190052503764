import { ParametrosService } from './services/parametros.service';
import { MatSelectModule } from '@angular/material/select';
import { ConfiguracoesModule } from './components/modules/configuracoes/configuracoes.module';
import { LoginComponent } from './components/pages/login/login.component';
import { SolicitacaoService } from './services/domain/solicitacao.service';
import { AuthInterceptorProvider } from './interceptors/auth-interceptor';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from "@angular/material/badge";

import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './services/auth.service';
import { ContadorService } from './services/domain/contador.service'
import { StorageService } from './services/storage.service';
import { ContadorModule } from './components/modules/contador/contador.module';
import { FooterComponent } from './components/templates/footer/footer.component';
import { ClienteModule } from './components/modules/cliente/cliente.module';
import { SolicitacaoModule } from './components/modules/solicitacao/solicitacao.module';
import { NavComponent } from './components/templates/nav/nav.component';
import { HeaderComponent } from './components/templates/header/header.component';
import { ClienteService } from './services/domain/cliente.service'
import { NgxMaskModule } from 'ngx-mask';
import { UtilService } from './services/util.service';
import { VinculoContadorComponent } from './components/modules/cliente/vinculo-contador/vinculo-contador.component';
import { AlertModalComponent } from './components/modals/alert-modal/alert.modal.component';
import { DeleteComfirmationComponent } from './components/modals/delete-comfirmation/delete-comfirmation.component';
import { LocaleConfig } from './config/locale.config';
import { UsuarioModule } from './components/modules/usuario/usuario.module';

@NgModule({
  exports: [
    MatSidenavModule
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    NavComponent,
    FooterComponent,
    VinculoContadorComponent,
    LoginComponent,
    AlertModalComponent,
    DeleteComfirmationComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ContadorModule,
    ClienteModule,
    SolicitacaoModule,
    ConfiguracoesModule,
    UsuarioModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    FormsModule,
    MatInputModule,
    HttpClientModule,
    MatTableModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    CommonModule,
    NgxMaskModule.forRoot(),
    MatDialogModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSelectModule,
    MatOptionModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatBadgeModule
  ],
  providers: [
    AuthService,
    StorageService,
    ContadorService,
    ClienteService,
    UtilService,
    SolicitacaoService,
    ParametrosService,
    AuthInterceptorProvider,
    LocaleConfig,
    {
      provide: LOCALE_ID,
      deps: [LocaleConfig],
      useFactory: (localeConfig: LocaleConfig) => localeConfig.getLocale()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
