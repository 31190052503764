import { ClienteFormComponent } from './cliente-form/cliente-form.component';
import { ClienteComponent } from './cliente.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: '',
    component: ClienteComponent,
  },
  {
    path: 'edit',
    component: ClienteFormComponent
  },
  {
    path: 'edit/:id',
    component: ClienteFormComponent
  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })

export class MercadoRoutingModule { }