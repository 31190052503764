import { Component, OnInit, ViewChild } from '@angular/core';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { UsuarioDTO } from 'src/app/models/usuario.dto';
import { UsuarioService } from 'src/app/services/domain/usuario.service';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioFormComponent } from './usuario-form/usuario-form.component';
import { UtilService } from 'src/app/services/util.service';
import { StorageService } from '../../../services/storage.service';
import { DeleteComfirmationComponent } from '../../modals/delete-comfirmation/delete-comfirmation.component';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public filter: string = '';
  public dataSource: MatTableDataSource<UsuarioDTO>;
  public usuarios: UsuarioDTO[] = [];
  public displayColumns = [
    // 'cpfCnpj', 
    'email', 
    'nome', 
    'perfilDeUsuario', 
    'acoes'];
  public selectValues = [
    { value: 'todos', viewValue: 'TODOS' },
    { value: '1', viewValue: 'ADMIN' },
    // { value: '2', viewValue: 'CONTADOR' },
    // { value: '3', viewValue: 'CLINTE' },
    { value: '4', viewValue: 'SUPORTE' }
  ];
  public selectedValue = 'todos'
  public deleteConfirmed: boolean;

  constructor(
    private usuarioService: UsuarioService,
    private utilService: UtilService,
    private dialog: MatDialog,
    public storage: StorageService
    ) { }

  ngOnInit(): void {
    this.findAll()
  }

  public async findAll() {
    return (await this.usuarioService.findAll())
      .subscribe(
        usuarios => {
          this.usuarios = usuarios.filter(function (UsuarioDTO) {
            return UsuarioDTO.perfilDeUsuario == 'ADMIN' || UsuarioDTO.perfilDeUsuario == 'SUPORTE'
          }).sort(function (a, b) {
            if (a.perfilDeUsuario < b.perfilDeUsuario) {
              return -1
            }
            if (a.perfilDeUsuario > b.perfilDeUsuario) {
              return 1
            }
            return 0
          })
          this.setDataSource()
        }
      )
  }

  public async onChange() {
    return (await this.usuarioService.findAllByPerfilDeUsuario(this.selectedValue))
      .subscribe(
        usuarios => {
          this.usuarios = usuarios.filter(function (UsuarioDTO) {
            return UsuarioDTO.perfilDeUsuario == 'ADMIN' || UsuarioDTO.perfilDeUsuario == 'SUPORTE'
          }).sort(function (a, b) {
            if (a.perfilDeUsuario < b.perfilDeUsuario) {
              return -1
            }
            if (a.perfilDeUsuario > b.perfilDeUsuario) {
              return 1
            }
            return 0
          })
          this.setDataSource()
        }
      )
  }

  public setDataSource() {
    this.dataSource = new MatTableDataSource(this.usuarios);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openUsuarioForm(id: string | null) {
    const dialogRef = this.dialog.open(
      UsuarioFormComponent, 
      {
        width: '400px',
        data: id
      }
    )
    dialogRef.afterClosed().subscribe(
      result => this.findAll()
    )
  }

  delete(id: string) {
    return this.usuarioService.delete(id)
      .subscribe(
        response => {
          this.utilService.showMessage('usuário deletado com sucesso!', 'snack-bar-success', null, "top")
          this.findAll()
          this.deleteConfirmed = false
        },
        e => this.utilService.showMessage(e.error.message, 'snack-bar-error', 5000, "top")
      )
  }

  public openDeleteConfirmation(usuario: UsuarioDTO) {
    let mensagem: string = `Confirmar a exclusão do usuário: \n${usuario.nome}`
    const dialogRef = this.dialog.open(DeleteComfirmationComponent, {
      width: '40em',
      height: '30em',
      data: {mensagem: mensagem, confirmed: this.deleteConfirmed},
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.delete(usuario.id!)
    });
  }
}
