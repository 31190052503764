import { SolicitacaoService } from '../../../../services/domain/solicitacao.service';
import { SolicitacaoDTO } from '../../../../models/solicitacao.dto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-solicitacao-detalhe',
  templateUrl: './solicitacao-detalhe.component.html',
  styleUrls: ['./solicitacao-detalhe.component.scss']
})
export class SolicitacaoDetalheComponent implements OnInit {

  solicitacao: SolicitacaoDTO = {
    cnpjCliente: '',
    cpfCnpjContadorSolicitante: '',
    periodoDe: '',
    periodoAte: '',
    dataSolicitacao: '',
    numeroDocumentoInicio: undefined,
    numeroDocumentoFim: undefined,
    serieInicio: undefined,
    serieFim: undefined,
    protocolo: '',
    status: undefined,
    urlArquivo: ''
  }

  constructor(
    public solicitacaoService: SolicitacaoService,
    private dialogRef: MatDialogRef<SolicitacaoDetalheComponent>,
    @Inject(MAT_DIALOG_DATA) public id: '',
    public storage: StorageService

  ) { }

  ngOnInit(): void {
    this.findById();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  async findById() {
    await this.solicitacaoService.findById(this.id)
      .subscribe(
        res => this.solicitacao = res
      )
  }

}
