import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/pages/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInAuthGuard } from './guards/logged-in-auth.guard';
import { AdminOrSupportGuard } from './guards/adminOrSupport.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/modules/cliente/cliente.module').then(m => m.ClienteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedInAuthGuard]
  },
  {
    path: 'clientes',
    loadChildren: () => import('./components/modules/cliente/cliente.module').then(m => m.ClienteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contadores',
    loadChildren: () => import('./components/modules/contador/contador.module').then(m => m.ContadorModule),
    canActivate: [AuthGuard, AdminOrSupportGuard]
  },
  {
    path: 'solicitacoes',
    loadChildren: () => import('./components/modules/solicitacao/solicitacao.module').then(m => m.SolicitacaoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./components/modules/cliente/cliente.module').then(m => m.ClienteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuracoes',
    loadChildren: () => import('./components/modules/configuracoes/configuracoes.module').then(m => m.ConfiguracoesModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./components/modules/usuario/usuario.module').then(m => m.UsuarioModule),
    canActivate: [AuthGuard, AdminGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

