import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClienteDTO } from '../../../../models/cliente.dto';
import { Component, OnInit, Inject } from '@angular/core';
import { ClienteService } from 'src/app/services/domain/cliente.service';
import { UtilService } from 'src/app/services/util.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-cliente-form',
  templateUrl: './cliente-form.component.html',
  styleUrls: ['./cliente-form.component.scss']
})
export class ClienteFormComponent implements OnInit {

  public cliente: ClienteDTO = {
    id: '',
    nome: '',
    email: '',
    cnpj: '',
    senha: '',
    razao: '',
    contato: '',
    telefone: ''
  };

  cpfContador: string = '';

  constructor(
    private clienteService: ClienteService,
    private dialogRef: MatDialogRef<ClienteFormComponent>,
    @Inject(MAT_DIALOG_DATA) public id: string,
    private utilService: UtilService,
    public storage: StorageService
  ) { }

  ngOnInit(): void {
    this.findById()
  }

  createCliente() {
    this.clienteService.create(this.cliente)
      .subscribe(
        response => {
          this.utilService.showMessage("cliente cadastrado com sucesso", 'snack-bar-success', null, "top");
          this.onNoClick();
        },
        e => this.utilService.showMessage(e.error.message, 'snack-bar-error', 5000, "top")
      )

  }
  updateCliente() {

    this.clienteService.update(this.cliente)
      .subscribe(
        response => {
          this.utilService.showMessage("cliente atualizado com sucesso", 'snack-bar-success', null, "top");
          this.onNoClick();
        },
        e => this.utilService.showMessage(e.error.message, 'snack-bar-error', 5000, "top")
      )

  }

  findById() {
    if (this.id != null) {
      this.clienteService.findById(this.id!).subscribe(
        cliente => {
          this.cliente = cliente
        }
      );
    }
  }
  cancel() {
    this.onNoClick();

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
