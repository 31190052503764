<div class="container">
  <h1 id="titulo">NOVA SOLICITAÇÃO</h1>
    <mat-form-field appearance="outline">
        <mat-label>CNPJ Cliente</mat-label>
        <input matInput placeholder="Apenas numeros" mask="00.000.000/0000-00" [(ngModel)]="solicitacao.cnpjCliente" disabled *ngIf="!usuarioMaster">
        <input (change)="validaCpfCnpjCliente()" matInput placeholder="Apenas numeros" mask="00.000.000/0000-00" [(ngModel)]="solicitacao.cnpjCliente" *ngIf="usuarioMaster">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>CPF/CNPJ Solicitante</mat-label>
        <input matInput placeholder="Apenas numeros" mask="00.000.000/0000-00||000.000.000-00"
            [(ngModel)]="solicitacao.cpfCnpjContadorSolicitante" disabled *ngIf="!usuarioMaster">
        <input (change)="validaCpfCnpjSolicitante()" matInput placeholder="Apenas numeros" mask="00.000.000/0000-00||000.000.000-00"
            [(ngModel)]="solicitacao.cpfCnpjContadorSolicitante" *ngIf="usuarioMaster">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Periodo Desejado</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate" required>
          <input matStartDate formControlName="start" placeholder="Data Inicial" required>
          <input matEndDate formControlName="end" placeholder="Data Final" required>
        </mat-date-range-input>
        <mat-hint style="font-size: 10px;">Máximo 31 dias</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Documento inicial</mat-label>
        <input
        matInput
        placeholder="Apenas numeros"
        onfocus="this.select()"
        [maxLength]="9"
        (keypress)="numericOnly($event)"
        [(ngModel)]="solicitacao.numeroDocumentoInicio">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Documento final</mat-label>
        <input
        matInput
        placeholder="Apenas numeros"
        onfocus="this.select()"
        (change)="validaNumeroDocumento()"
        [maxLength]="9"
        (keypress)="numericOnly($event)"
        [(ngModel)]="solicitacao.numeroDocumentoFim">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Serie inicial</mat-label>
        <input
        matInput
        placeholder="Apenas numeros"
        onfocus="this.select()"
        [maxLength]="3"
        (keypress)="numericOnly($event)"
        [(ngModel)]="solicitacao.serieInicio">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Serie final</mat-label>
        <input
        matInput
        placeholder="Apenas numeros"
        onfocus="this.select()"
        (change)="validaNumeroSerie()"
        [maxLength]="3"
        (keypress)="numericOnly($event)"
        [(ngModel)]="solicitacao.serieFim">
    </mat-form-field>
    <button mat-raised-button class="btn-confirm" (click)="create(solicitacao)">
        ENVIAR
    </button>
    <button mat-raised-button class="btn-cancel" (click)="onNoClick()">
        CANCELAR
    </button>
</div>
