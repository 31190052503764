<mat-sidenav-container class="container">
  <mat-sidenav class="sidenav mat-elevation-z4" mode="side" opened fixedInViewport="true"
    *ngIf="storage.getUserProfile() == 'ADMIN' || storage.getUserProfile() == 'SUPORTE'">
    <img class="logo" src=".../../../../../../assets/logoLgin.png" alt="logo" routerLink="/" >
    <mat-nav-list class="nav-lis">
      <a mat-list-item routerLink="/">
        <mat-icon>home</mat-icon>
        Inicio
      </a>
      <a mat-list-item routerLink="/contadores" routerLinkActive="active-list-item">
        <mat-icon>people</mat-icon>
        Contador
      </a>
      <a mat-list-item routerLink="/clientes" routerLinkActive="active-list-item">
        <mat-icon>store</mat-icon>
        Cliente
      </a>
      <a mat-list-item routerLink="/solicitacoes" routerLinkActive="active-list-item">
        <mat-icon>file_present</mat-icon>
        Solicitação
      </a>
      <a mat-list-item routerLink="/usuarios" routerLinkActive="active-list-item" *ngIf="storage.getUserProfile() == 'ADMIN'">
        <mat-icon>account_circle</mat-icon>
        Usuários
      </a>
      <a mat-list-item routerLink="/configuracoes" routerLinkActive="active-list-item" *ngIf="storage.getUserProfile() == 'ADMIN'">
        <mat-icon>settings</mat-icon>
        Configurações
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="content">
    <app-header></app-header>
    <div class="content-body">
      <router-outlet></router-outlet>
    </div>
    <!-- <app-footer *ngIf="storage.getLocalUser()?.perfilDeUsuario != 'ADMIN'"></app-footer> -->
  </mat-sidenav-content>
</mat-sidenav-container>
