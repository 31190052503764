<div class="bind-client">
  <h2>Vincular cliente(s)</h2>
  <mat-form-field appearance="outline">
    <mat-label>Contador</mat-label>
    <input
      matInput
      type="text"
      name="nome"
      [(ngModel)]="this.contador.nome + ' - ' + this.contador.cnpj"
      [readonly]="true"
    />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Clientes</mat-label>
    <mat-select
      [formControl]="clientesSelecionados"
      [compareWith]="compareClientes"
      multiple
    >
      <mat-option *ngFor="let cliente of todosClientes" [value]="cliente">
        {{ cliente.nome + " - " + cliente.cpfCnpj }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button
    mat-stroked-button
    (click)="saveClientesContador()"
    class="btn-confirm"
  >
    SALVAR
  </button>
  <button mat-raised-button (click)="closed()" class="btn-cancel">
    CANCELAR
  </button>
</div>
