import { ClienteService } from './../../../services/domain/cliente.service';
import { UtilService } from 'src/app/services/util.service';
import { DeleteComfirmationComponent } from './../../modals/delete-comfirmation/delete-comfirmation.component';
import { SolicitacaoNewDTO } from './../../../models/solicitacao.new.dto';
import { AlertModalComponent } from './../../modals/alert-modal/alert.modal.component';
import { ActivatedRoute } from '@angular/router';
import { SolicitacaoFormComponent } from './solicitacao-form/solicitacao-form.component';
import { StorageService } from '../../../services/storage.service';
import { LocalUser } from '../../../models/local_user';
import { SolicitacaoDetalheComponent } from './solicitacao-detalhe/solicitacao-detalhe.component';
import { MatDialog, } from '@angular/material/dialog';
import { SolicitacaoService } from '../../../services/domain/solicitacao.service';
import { SolicitacaoDTO } from '../../../models/solicitacao.dto';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-solicitacao',
  templateUrl: './solicitacao.component.html',
  styleUrls: ['./solicitacao.component.scss']
})

export class SolicitacaoComponent implements OnInit {

  public filter: string = '';
  public deleteConfirmed: boolean = false;
  public usuarioLogado!: LocalUser;
  public solicitacoes: SolicitacaoDTO[] = [];
  public displayColumns!: string[];
  public widthViewPort: number = window.screen.width;
  public dataSource: MatTableDataSource<SolicitacaoDTO>;
  public newSolicitacaoDTO: SolicitacaoNewDTO = {
    cnpjCliente: '',
    cpfCnpjContadorSolicitante: '',
    periodoDe: '',
    periodoAte: '',
  }

  public header = {
    nomeFantasia: '',
    cnpjCliente: '',
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoading = true;

  constructor(
    public solicitacaoService: SolicitacaoService,
    private dialog: MatDialog,
    public storage: StorageService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private clienteService: ClienteService
  ) { }

  ngOnInit(): void {
    this.findAll()
    this.setColunsTable()
  }

  ngAfterViewInit(): void {
    this.sort.disableClear = true;
    this.sort.sort({disableClear: true, id:'dataSolicitacao',start:'desc'})
    this.headerInformation();
  }

  public findAll() {
    if (this.storage.getLocalUser()?.perfilDeUsuario == 'ADMIN' || this.storage.getLocalUser()?.perfilDeUsuario == 'SUPORTE') {
      this.solicitacaoService.findAll()
        .subscribe(
          response => {
            this.solicitacoes = response
            this.setDataSource()
          }, e => {
            this.openAlert(e.error.message);
          }
        )
    } else {
      const cnpj = this.route.snapshot.paramMap.get('cnpj');
      const cpfcnpj = this.storage.getLocalUser()?.cpfCnpj!;
      let resFilter = new Array<SolicitacaoDTO>();
      if (cnpj != null) {
        this.solicitacaoService.findByCnpj(cnpj!).subscribe(
          res => {
            res.forEach( solicitacoes => {
              if(cpfcnpj == solicitacoes.cpfCnpjContadorSolicitante) {
                resFilter.push(solicitacoes);
              }
            });
            this.solicitacoes = resFilter;
            this.setDataSource();
          },
          e => {
            this.openAlert(e.error.message);
          }
        )
      }
    }

    window.setTimeout(
      () => {
        this.findAll()
      }, 300000
    );
  }
  private del(protocolo: string) {
    this.solicitacaoService.delete(protocolo).subscribe(
      res => {
        this.utilService.showMessage("Solicitação deletada com sucesso", 'snack-bar-success', null, "top")
      },
      e => {
        this.openAlert(e.error.message)
      }
    )
  }
  private setDataSource() {
    this.dataSource = new MatTableDataSource(this.solicitacoes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  private setColunsTable() {
    if (this.storage.getLocalUser()?.perfilDeUsuario == "ADMIN" || this.storage.getLocalUser()?.perfilDeUsuario == "SUPORTE") {
      this.displayColumns = ['cnpjCliente', 'cpfCnpjContadorSolicitante', 'protocolo', 'dataSolicitacao', 'dataExpiracao','status','acoes']
    } else {
      this.displayColumns = ['protocolo', 'dataSolicitacao', 'dataExpiracao', 'status','acoes']
    }
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public detalhar(id: string) {
    this.dialog.open(
      SolicitacaoDetalheComponent,
      {
        data: id,
        width: '35em',
        height: '40em'
      }
    )
  }
  public voltar() {
    window.history.back()
  }
  public newSolicitacao() {
    this.newSolicitacaoDTO.cnpjCliente = this.route.snapshot.paramMap.get('cnpj')!
    this.newSolicitacaoDTO.cpfCnpjContadorSolicitante = this.storage.getLocalUser()?.cpfCnpj!
    const dialogRef = this.dialog.open(
      SolicitacaoFormComponent,
      {
        width: '35em',
        height: '60em',
        data: this.newSolicitacaoDTO
      }
    )
    dialogRef.afterClosed().subscribe(async close => {
      await this.findAll();
    })
  }

  private openAlert(mensagem: string) {
    this.dialog.open(
      AlertModalComponent, {
      width: (window.screen.width > 900) ? '30%' : '60%',
      data: mensagem
    }
    )
  }
  public dateFromString(date: string): Date {
    return new Date(date)
  }
  public openDeleteConfirmation(protocolo: string) {
    let mensagem: string = `Confirma a exclusão da solicitação protocolo: \n${protocolo}`
    const dialogRef = this.dialog.open(DeleteComfirmationComponent, {
      width: '35em',
      data: { mensagem: mensagem, confirmed: this.deleteConfirmed },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.del(protocolo)
    });
  }

  public async headerInformation() {
    if(this.storage.getLocalUser()?.cpfCnpj) {
      await this.clienteService.findByCnpj(
        this.route.snapshot.paramMap.get('cnpj')!)
        .subscribe(cliente => {
          this.header.nomeFantasia = cliente.nome!
          this.header.cnpjCliente = cliente.cpfCnpj!
        });
    }
  }
}
