<div class="edit">
    <h2>Contador</h2>
    <form>
        <!-- CpfCnpj do contador -->
        <mat-form-field appearance="outline">
            <input matInput type="text" placeholder="Apenas numeros" mask='00.000.000/0000-00||000.000.000-00'
                name="cpfCnpj" [(ngModel)]="contador.cpfCnpj" required [disabled]="storage.getUserProfile() == 'SUPORTE'"/>
            <mat-label>CPF/CNPJ</mat-label>
        </mat-form-field>

        <!-- Nome do contador -->
        <mat-form-field appearance="outline">
            <input matInput type="text" name="nome" placeholder="Ex: Empresa XYZ" [(ngModel)]="contador.nome" required [disabled]="storage.getUserProfile() == 'SUPORTE'"/>
            <mat-label>Nome</mat-label>
        </mat-form-field>

        <!-- Contabilidade Contador -->
        <mat-form-field appearance="outline" *ngIf="contador.id != ''" >
            <input matInput type="text" name="contabilidade" placeholder="Ex: Empresa XYZ LTDA."
                [(ngModel)]="contador.contabilidade" required [disabled]="storage.getUserProfile() == 'SUPORTE'"/>
            <mat-label>Contabilidade</mat-label>
        </mat-form-field>

        <!-- Email do contador -->
        <mat-form-field appearance="outline" *ngIf="contador.id != ''">
            <input matInput type="text" name="email" placeholder="Ex: seuemail@email.com"
                [(ngModel)]="contador.email" required [disabled]="storage.getUserProfile() == 'SUPORTE'"/>
            <mat-label>Email</mat-label>
        </mat-form-field>

        <!-- Senha do contador -->
        <mat-form-field appearance="outline" *ngIf="contador.id != ''">
            <input matInput type="text" placeholder="Ex: senha de sua escolha" name="responsavel"
                [(ngModel)]="contador.senha" [disabled]="storage.getUserProfile() == 'SUPORTE'"/>
            <mat-label>Senha (em branco não altera)</mat-label>
        </mat-form-field>
    </form>
        <button mat-stroked-button (click)="(contador.id == '' || contador.id == null) ? create() : update() "
            [disabled]="shouldDisableSave()"
            class="btn-confirm"
            *ngIf="storage.getUserProfile() != 'SUPORTE'">
            SALVAR
        </button>
        <button mat-raised-button (click)="closed()" class="btn-cancel">
            CANCELAR
        </button>
</div>
