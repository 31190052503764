
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ListaClientesContadorDTO } from 'src/app/models/lista-clientes-contador.dto';
import { ClienteDTO } from 'src/app/models/cliente.dto';
import { UsuarioIdNomeDTO } from 'src/app/models/usuario-id-nome.dto';
import { ClienteService } from 'src/app/services/domain/cliente.service';
import { ContadorService } from 'src/app/services/domain/contador.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-contador-vincular-cliente',
  templateUrl: './contador-vincular-cliente.component.html',
  styleUrls: ['./contador-vincular-cliente.component.scss'],
})
export class ContadorVincularClienteComponent implements OnInit {
  formGroup: UntypedFormGroup;
  todosClientes: UsuarioIdNomeDTO[] = [];
  clientesContador: UsuarioIdNomeDTO[] = [];
  clientesSelecionados = new UntypedFormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public contador: { cnpj: string; nome: string },
    private clienteService: ClienteService,
    private contadorService: ContadorService,
    private utilService: UtilService,
    private dialogRef: MatDialogRef<ContadorVincularClienteComponent>,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.initializeForm();
  }

  private async initializeForm(): Promise<void> {
    await this.findAllClients();
    await this.findClientesByContador(this.contador.cnpj);
    this.clientesSelecionados.setValue(this.clientesContador);
  }

  private async findAllClients(): Promise<void> {
    try {
      await this.clienteService
        .findAll()
        .toPromise()
        .then((clientes) => {
          this.todosClientes = this.convertToUsuarioIdNomeDTO(clientes);
        });
    } catch (error) {
      console.error('Erro ao obter clientes ', error);
    }
  }

  private async findClientesByContador(cpfcnpj: string): Promise<void> {
    try {
      await this.contadorService
        .findClientesByContador(cpfcnpj)
        .toPromise()
        .then((clientes) => {
          this.clientesContador = this.convertToUsuarioIdNomeDTO(clientes);
        });
    } catch (error) {
      console.error('Erro ao obter clientes do contador: ', error);
    }
  }

  private convertToUsuarioIdNomeDTO(
    clientes: ClienteDTO[]
  ): UsuarioIdNomeDTO[] {
    return clientes.map((cliente: ClienteDTO) => {
      return {
        cpfCnpj: cliente.cpfCnpj || cliente.cnpj || '',
        nome: cliente.nome || '',
      };
    });
  }

  public compareClientes(
    cliente1: UsuarioIdNomeDTO,
    cliente2: UsuarioIdNomeDTO
  ) {
    return cliente1?.cpfCnpj == cliente2?.cpfCnpj;
  }

  public saveClientesContador() {
    const listaClientesContador: ListaClientesContadorDTO = {
      cpfCnpjClientes: this.clientesSelecionados.value.map(
        (cliente: UsuarioIdNomeDTO) => cliente.cpfCnpj
      ),
    };
    this.contadorService
      .updateClientesContador(this.contador.cnpj, listaClientesContador)
      .subscribe(
        () => {
          this.utilService.showMessage(
            'Clientes do contador atualizado com sucesso',
            'snack-bar-success',
            null,
            "top"
          );
          this.closed();
        },
        (error) => {
          console.error(error);
          this.utilService.showMessage(
            error.error.message,
            'snack-bar-error',
            5000,
            "top"
          );
        }
      );
  }

  public closed() {
    this.dialogRef.close();
  }
}
