import { AlertModalComponent } from '../../../modals/alert-modal/alert.modal.component';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ClienteService } from 'src/app/services/domain/cliente.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-vinculo-contador',
  templateUrl: './vinculo-contador.component.html',
  styleUrls: ['./vinculo-contador.component.scss']
})
export class VinculoContadorComponent implements OnInit {

  cnpjCliente: string = '';
  cpfCnpjContador: string = ''

  constructor(
    private dialogRef: MatDialogRef<VinculoContadorComponent>,
    private clienteService: ClienteService,
    private utilService: UtilService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  vincular() {
    if (this.cnpjCliente == '' || this.cpfCnpjContador == '') {
      this.utilService.showMessage("preencha os campos", 'snack-bar-error', 5000, "top")
      return
    }
    this.clienteService.updateContadorCliente(this.cnpjCliente, this.cpfCnpjContador)
      .subscribe(
        response => {
          this.utilService.showMessage("vinculo realizado com sucesso", 'snack-bar-success', null, "top")
          this.cancel()
        },
        e => {
          this.dialog.open(
            AlertModalComponent,
            {
              data: e.error.message
            }
          )
        }
      )

  }
  cancel() {
    this.dialogRef.close();
  }



}
