<div class="container">
    <h1>{{data.mensagem}}</h1>
    <mat-form-field appearance="outline">
        <mat-label>Seu email</mat-label>
        <input required name="email" onfocus="this.select()" [(ngModel)]="credencial.email"
            placeholder="seuemail@email.com" matInput>
        <mat-icon mat-icon matSuffix>
            mail
        </mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Sua senha</mat-label>
        <input required name="senha" onfocus="this.select()" [(ngModel)]="credencial.senha" matInput
            [type]="hide == 'password' ? 'password' : 'text'" placeholder="sua senha" (keyup.enter)="login()">
        <button class="icon" mat-icon-button matSuffix (click)="passwordVisibility()">
            <mat-icon>
                {{hide == 'password' ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </button>
    </mat-form-field>
    <button class="submit" mat-raised-button (click)="login()">CONFIRMAR</button>
    <button mat-raised-button (click)="onNoClick()" class="cancelar">
        CANCELAR
    </button>
</div>