<mat-toolbar class="header mat-elevation-z4 v-middle" fixedInViewport="true">
    <img src="../../../../assets/arte_portal.png" alt="logo" routerLink="/home"
        *ngIf="storage.getLocalUser()?.perfilDeUsuario != 'ADMIN' && storage.getLocalUser()?.perfilDeUsuario != 'SUPORTE' && viewWidth > 768">
    <div class="usuario" (click)="openModal()" title="Perfil">
        <mat-icon>account_circle</mat-icon>
        <div class="dados-usuario">
            <h2>{{storage.getLocalUser()?.nome | uppercase}}</h2>
            <h5>{{storage.getLocalUser()?.perfilDeUsuario| uppercase}}</h5>
        </div>
    </div>
    <button mat-raised-button (click)="logout()" class="logout admin"
        *ngIf="storage.getLocalUser()?.perfilDeUsuario == 'ADMIN' || storage.getLocalUser()?.perfilDeUsuario == 'SUPORTE'">Sair <mat-icon class="icon-display">logout</mat-icon>
    </button>
    <button mat-raised-button (click)="logout()" class="logout"
        *ngIf="storage.getLocalUser()?.perfilDeUsuario != 'ADMIN' && storage.getLocalUser()?.perfilDeUsuario != 'SUPORTE'">Sair <mat-icon class="icon-display">logout</mat-icon>
    </button>
    <button mat-raised-button (click)="logout()" class="logout-mobile">
        <mat-icon class="icon-button">logout</mat-icon>
    </button>

</mat-toolbar>
