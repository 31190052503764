<div mat-dialog-content>
    <h1><b>DETALHES DA SOLICITAÇÃO</b></h1>
    <span>Cliente: <b>{{ solicitacao.cnpjCliente | mask: '00.000.000/0000-00' }}</b></span>
    <span>Data solicitação: <b>{{ solicitacao.dataSolicitacao | date: 'dd/MM/yyyy' }}</b></span>
    <span>Data disponibilização: <b>{{solicitacao.dataDisponibilizacao ? (solicitacao.dataDisponibilizacao | date: 'dd/MM/yyyy') : 'Processando' }}</b></span>
    <span>Periodo inicial: <b>{{ solicitacao.periodoDe | date: 'dd/MM/yyyy' }}</b></span>
    <span>Periodo final: <b>{{ solicitacao.periodoAte | date: 'dd/MM/yyyy' }}</b></span>
    <span>Documento inicial: <b>{{ solicitacao.numeroDocumentoInicio }}</b></span>
    <span>Documento final: <b>{{ solicitacao.numeroDocumentoFim }}</b></span>
    <span>Serie inicial: <b>{{ solicitacao.serieInicio }}</b></span>
    <span>Serie final: <b>{{ solicitacao.serieFim }}</b></span>
    <span>Protocolo: <b>{{ solicitacao.protocolo }}</b></span>
    <button mat-raised-button disabled *ngIf="solicitacao.status == 1">
        PENDENTE
    </button>
    <button mat-raised-button disabled *ngIf="solicitacao.status == 5">
        NÃO ENCONTRADO
    </button>
    <a href="{{ solicitacao.urlArquivo }}" *ngIf="solicitacao.urlArquivo && solicitacao.status != 4 && storage.getLocalUser()?.perfilDeUsuario != 'SUPORTE'">
        <button mat-raised-button>
            DOWNLOAD DOS XML
        </button>
    </a>
    <button mat-raised-button disabled *ngIf="solicitacao.status == 4">
      EXPIRADA
  </button>
</div>
