import { UtilService } from './../../../services/util.service';
import { EmailParametro } from './../../../models/email-parametro.dto';
import { ParametroGeral } from './../../../models/parametro-geral.dto';
import { ParametrosService } from './../../../services/parametros.service';
import { Component, OnInit } from '@angular/core';
import { Parametro } from 'src/app/models/parametro.enum';

@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html',
  styleUrls: ['./configuracoes.component.scss']
})
export class ConfiguracoesComponent implements OnInit {

  public parametroValidadeDownload: ParametroGeral ={
    id: null!,
    parametro: null!,
    valor: null!
  };
  public parametroHorarioExclusao: ParametroGeral ={
    id: null!,
    parametro: null!,
    valor: null!
  };
  public parametroHorarioEnvioLembrete: ParametroGeral ={
    id: null!,
    parametro: null!,
    valor: null!
  };
  public parametroEmail: EmailParametro ={
    id:null!,
    host:null!,
    port:null!,
    username:null!,
    password:null!,
    emailSender:null!,
    titleEmailSenha:null!,
    textEmailSenha:null!,
    titleEmailLembrete:null!,
    textEmailLembrete:null!,
    titleEmailDownload:null!,
    textEmailLinkDownload:null!,
    titleEmailXmlsNaoEncontrados:null!,
    textEmailXmlsNaoEncontrados:null!
  };

  constructor(
    private parametroService: ParametrosService,
    private util: UtilService
    ) { }

  ngOnInit(): void {
    this.findAllParametros();

  }
  public async findValidadeDownload(){
    await this.parametroService.findParametroById(Parametro.VALIDADE_DOWNLOAD).subscribe(
      (parametro: ParametroGeral) => {this.parametroValidadeDownload = parametro;});
  }
  public async findParametroHorarioExclusao(){
    await this.parametroService.findParametroById(Parametro.HORARIO_EXCLUSAO).subscribe(
      (parametro: ParametroGeral) => {this.parametroHorarioExclusao = parametro;});
  }
  public async findParametroHorarioEnvioLembrete(){
    await this.parametroService.findParametroById(Parametro.HORARIO_ENVIO_LEMBRETE).subscribe(
      (parametro: ParametroGeral) => {this.parametroHorarioEnvioLembrete = parametro;});
  }
  public async findEmailParam(){
    await this.parametroService.findEmailParam().subscribe(
      (parametro: EmailParametro) => {this.parametroEmail = parametro;});
  }
  public async findAllParametros(){
    await this.findEmailParam();
    await this.findValidadeDownload();
    await this.findParametroHorarioExclusao();
    await this.findParametroHorarioEnvioLembrete();
  }
  public async updateParametrosGerais(){
    let parametros: ParametroGeral[] = [];
    parametros.push(this.parametroValidadeDownload);
    parametros.push(this.parametroHorarioExclusao);
    parametros.push(this.parametroHorarioEnvioLembrete);
    await this.parametroService.updateParametrosGerais(parametros).subscribe(
      () => {
        this.findAllParametros();
        this.util.showMessage('Parametros atualizados com sucesso!', 'snack-bar-success', null, "top");
      }
    );
  }
  public async updateEmailParam(){
    await this.parametroService.updateEmailParam(this.parametroEmail).subscribe(
      () => {
        this.findAllParametros();
        this.util.showMessage('Parametros atualizados com sucesso!', 'snack-bar-success', null, "top");
      }
    );
  }

}
