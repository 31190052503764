import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{


  constructor(
    private authService: AuthService,
    private storage: StorageService
  ) { }

  ngOnInit() {
    if (this.storage.getLocalUser()?.usuarioLogado === "TRUE") {
      this.authService.userAuthenticated = true;
    }
    this.authService.refreshToken()?.subscribe(res => {
      this.authService.userAuthenticated = true;
      this.authService.successfulLogin(res.body?.token!);
    },
    error => {
      this.authService.userAuthenticated = false;
      this.authService.logout();
    });
  }

  isAuthenticated(): boolean {
    return this.authService.userAuthenticated;
  }

  title = 'xml-services-front';
}
