import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContadorDTO } from 'src/app/models/contador.dto';
import { ContadorService } from 'src/app/services/domain/contador.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-contador-alterar-senha',
  templateUrl: './contador-alterar-senha.component.html',
  styleUrls: ['./contador-alterar-senha.component.scss']
})
export class ContadorAlterarSenhaComponent implements OnInit {

  contador: ContadorDTO = {
    id: '',
    nome: '',
    email: '',
    senha: '',
    cpfCnpj: '',
    contabilidade: '',
    perfilDeUsuario: ''
  }

  constructor(
    private utilService: UtilService,
    private dialogRef: MatDialogRef<ContadorAlterarSenhaComponent>,
    private contadorService: ContadorService,
    public storage: StorageService,
  ) { }

  ngOnInit(): void {
     this.findById(String(this.storage.getLocalUser()?.cpfCnpj));
  }

  public create() {
    this.contadorService.create(this.contador)
      .subscribe(
        response => {
          this.utilService.showMessage("contador cadastrado com sucesso", 'snack-bar-success', null, "top");
          this.closed();

        },
        e => this.utilService.showMessage(e.error.message, 'snack-bar-error', 5000, "top")
      )

  }
  public update() {
    this.contadorService.update(this.contador)
      .subscribe(
        response => {
          this.utilService.showMessage("contador atualizado com sucesso", 'snack-bar-success', null, "top");
          this.closed();
        },
        e => this.utilService.showMessage(e.error.message, 'snack-bar-error', 5000, "top")
      )

  }

  public closed() {
    this.dialogRef.close();
  }

  private findById(cpfcnpj: string) {
    this.contadorService.findByCpfCnpj(cpfcnpj).subscribe(
      contador => {
        this.contador = contador
      }
    )
    }
  }
