import { Component, OnInit } from '@angular/core';
import { StorageService } from './../../../services/storage.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(public storage: StorageService) { }

  ngOnInit(): void {
    this.storage.getLocalUser()?.perfilDeUsuario
  }

}
