import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatOptionModule } from '@angular/material/core';
import { ContadorRoutingModule } from './contador.routing.module';
import { ContadorComponent } from './contador.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContadorFormComponent } from './contador-form/contador-form.component';
import { NgxMaskModule } from 'ngx-mask';
import { ContadorAlterarSenhaComponent } from './contador-alterar-senha/contador-alterar-senha.component';
import { ContadorVincularClienteComponent } from './contador-vincular-cliente/contador-vincular-cliente.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";


@NgModule({
  declarations: [
    ContadorFormComponent,
    ContadorComponent,
    ContadorAlterarSenhaComponent,
    ContadorVincularClienteComponent
  ],
  imports: [
    CommonModule,
    ContadorRoutingModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    NgxMaskModule,
    MatIconModule,
    MatSortModule,
    MatPaginatorModule,
    MatCardModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule
  ]
})
export class ContadorModule { }
