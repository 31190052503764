import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DatePipe } from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private snackbar: MatSnackBar,
              private datePipe: DatePipe
              ) { }

  public showMessage(msg: string, classCss: string, duration: number | null, position:MatSnackBarVerticalPosition): void {
    this.snackbar.open(msg, 'x', {
      duration: duration || 3000,
      horizontalPosition: "center",
      verticalPosition: position,
      panelClass: [classCss]
    });
  }

}
