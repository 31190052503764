import { SolicitacaoMultipleNewDTO } from 'src/app/models/solicitacao.multiple.new.dto';
import { environment } from './../../../environments/environment';
import { SolicitacaoNewDTO } from './../../models/solicitacao.new.dto';
import { HttpClient } from '@angular/common/http';
import { SolicitacaoDTO } from './../../models/solicitacao.dto';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

const BASE_URL: string = environment.baseUrl
const END_POINT: string = "consulta"
@Injectable({
  providedIn: 'root'
})
export class SolicitacaoService {

  constructor(
    private http: HttpClient
  ) { }

  public findAll(): Observable<SolicitacaoDTO[]> {
    return this.http.get<SolicitacaoDTO[]>(`${BASE_URL}/${END_POINT}`)
  }
  public findById(id: string): Observable<SolicitacaoDTO> {
    return this.http.get<SolicitacaoDTO>(`${BASE_URL}/${END_POINT}/id/${id}`)
  }
  public findByCnpj(cnpj: string): Observable<SolicitacaoDTO[]> {
    return this.http.get<SolicitacaoDTO[]>(`${BASE_URL}/${END_POINT}/cnpj/${cnpj}`)
  }
  public create(solicitacao: SolicitacaoNewDTO): Observable<SolicitacaoNewDTO> {
    return this.http.post<SolicitacaoNewDTO>(`${BASE_URL}/${END_POINT}`, solicitacao)
  }
  public delete(protocolo: string): Observable<SolicitacaoDTO> {
    return this.http.delete<SolicitacaoDTO>(`${BASE_URL}/${END_POINT}/protocolo/${protocolo}`)
  }
  public createMultiple(solicitacoes: Array<SolicitacaoNewDTO>): Observable<Array<SolicitacaoNewDTO>> {
    return this.http.post<Array<SolicitacaoNewDTO>>(`${BASE_URL}/${END_POINT}/multiplo`, solicitacoes)
  }

}
