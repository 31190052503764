import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Pipe({
  name: 'filter',
  pure: false
})
@Injectable()
export class FilterPipe implements PipeTransform {

  transform(items: any, term: string, excludes = []): any {
    if (!term || !items) return items;

    return FilterPipe.filter(items, term, excludes);
  }

  static filter(items: Array<{ [key: string]: any }>, term: string, excludes: any): Array<{ [key: string]: any }> {
    const toCompare = term.toLowerCase();

    function checkInside(item: any, term: string): boolean {
      if (typeof item === "string" && item.toLowerCase().includes(toCompare)) {
        return true;
      }

      for (const property in item) {
        if (!item.hasOwnProperty(property) || excludes.includes(property)) {
          continue;
        }

        const value = item[property];
        if (typeof value === 'object' && checkInside(value, term)) {
          return true;
        }

        if (value.toString().toLowerCase().includes(toCompare)) {
          return true;
        }
      }

      return false;
    }

    return items.filter((item) => checkInside(item, term));
  }
}
