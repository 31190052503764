import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContadorService } from '../../../../services/domain/contador.service';
import { ContadorDTO } from '../../../../models/contador.dto';
import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-contador-form',
  templateUrl: './contador-form.component.html',
  styleUrls: ['./contador-form.component.scss']
})
export class ContadorFormComponent implements OnInit {

  contador: ContadorDTO = {
    id: '',
    nome: '',
    email: '',
    senha: '',
    cpfCnpj: '',
    contabilidade: '',
    perfilDeUsuario: ''
  }

  constructor(
    private utilService: UtilService,
    private dialogRef: MatDialogRef<ContadorFormComponent>,
    @Inject(MAT_DIALOG_DATA) public id: string,
    private contadorService: ContadorService,
    public storage: StorageService
  ) { }

  ngOnInit(): void {
    this.findById(this.id)
  }

  public create() {
    this.contadorService.create(this.contador)
      .subscribe(
        response => {
          this.utilService.showMessage("contador cadastrado com sucesso", 'snack-bar-success', null, "top");
          this.closed();

        },
        e => this.utilService.showMessage(e.error.message, 'snack-bar-error', 5000, "top")
      )

  }
  public update() {
    this.contadorService.update(this.contador)
      .subscribe(
        response => {
          this.utilService.showMessage("contador atualizado com sucesso", 'snack-bar-success', null, "top");
          this.closed();
        },
        e => {
          this.utilService.showMessage(e.error.message, 'snack-bar-error', 5000, "top")
        }
      )

  }

  public closed() {
    this.dialogRef.close();
  }

  shouldDisableSave() {

    const { id, cpfCnpj, nome, contabilidade, email  } = this.contador
    const requiredFields = id ? [ cpfCnpj, nome, contabilidade, email ] : [ cpfCnpj, nome ]
    return requiredFields.some(x => !x)

  }

  private findById(id: string) {
    if (id != null) {
      this.contadorService.findById(id!).subscribe(
        contador => {
          this.contador = contador
        }
      );
    }
  }

}
