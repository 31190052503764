<div class="mat-elevation-z4 space-y2 table-container">
  <div class="table-header">
    <h1 class="header-title">Solicitações</h1>
    <div class="row-container">
      <mat-form-field appearance="outline" class="filter">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Busca de solicitação" #input [(ngModel)]="filter">
      </mat-form-field>

      <div class="headerInformation" textInformation>
        <h1 id="nomeFantasia">{{header.nomeFantasia}} </h1>
        <h2 id="cnpj">{{header.cnpjCliente | mask: '00.000.000/0000-00'}}</h2>
      </div>

      <div class="solicitacao-actions">
        <button mat-raised-button color="primary" (click)="newSolicitacao()" class="new" *ngIf="storage.getLocalUser()?.perfilDeUsuario != 'SUPORTE'">
          Nova solicitação
        </button>

        <button mat-raised-button class="detail" matTooltip="Recarregar listagem" [matTooltipPosition]="'above'"
          (click)="findAll()">
          <mat-icon>
            autorenew
          </mat-icon>
        </button>

        <button mat-raised-button class="detail voltar" (click)="voltar()" *ngIf="storage.getLocalUser()?.perfilDeUsuario != 'ADMIN' && storage.getLocalUser()?.perfilDeUsuario != 'SUPORTE'">
          Voltar
        </button>
      </div>
    </div>
  </div>

    <mat-table [dataSource]="dataSource" matSort>
      <!-- CNPJ DO CLIENTE Column -->
      <ng-container matColumnDef="cnpjCliente">
        <mat-header-cell *matHeaderCellDef mat-sort-header > CNPJ DO CLIENTE </mat-header-cell>
        <mat-cell *matCellDef="let solicitacao" matTooltip="{{solicitacao.nomeCliente}}"> {{solicitacao.cnpjCliente | mask: '00.000.000/0000-00'}} </mat-cell>
      </ng-container>

      <!-- CPF/CNPJ DO CONTADOR Column -->
      <ng-container matColumnDef="cpfCnpjContadorSolicitante" *ngIf="storage.getLocalUser()?.perfilDeUsuario == 'ADMIN' || storage.getLocalUser()?.perfilDeUsuario == 'SUPORTE'">
        <mat-header-cell *matHeaderCellDef mat-sort-header> CPF/CNPJ DO CONTADOR </mat-header-cell>
        <mat-cell *matCellDef="let solicitacao" matTooltip="{{solicitacao.nomeContador}}">
          {{ solicitacao.cpfCnpjContadorSolicitante.length < 14 ? (solicitacao.cpfCnpjContadorSolicitante | mask:'000.000.000-00') : (solicitacao.cpfCnpjContadorSolicitante | mask:'00.000.000/0000-00')}}
        </mat-cell>
      </ng-container>

      <!-- PROTOCOLO Column -->
      <ng-container matColumnDef="protocolo">
        <mat-header-cell *matHeaderCellDef mat-sort-header> PROTOCOLO </mat-header-cell>
        <mat-cell *matCellDef="let solicitacao"> {{ solicitacao.protocolo }} </mat-cell>
      </ng-container>

      <!-- STATUS Column -->
      <ng-container matColumnDef="status" >
        <mat-header-cell *matHeaderCellDef mat-sort-header> STATUS </mat-header-cell>
        <mat-cell *matCellDef="let solicitacao">
          <button mat-raised-button disabled *ngIf="solicitacao.status == 1" class="disabled">
            <span class="bold-disabled">PENDENTE</span>
          </button>
          <a href="{{solicitacao.urlArquivo}}" *ngIf="storage.getLocalUser()?.perfilDeUsuario != 'SUPORTE'">
            <button mat-raised-button *ngIf="solicitacao.status == 2" class="download" (click)="findAll()">
              DOWNLOAD
            </button>
          </a>
          <button mat-raised-button *ngIf="solicitacao.status == 2 && storage.getLocalUser()?.perfilDeUsuario == 'SUPORTE'" class="download">
            DOWNLOAD
          </button>
          <button mat-raised-button color="warn" *ngIf="solicitacao.status == 3" class="disabled" matTooltip="Para realizar o download novamente, utilize o menu de detalhes da solicitação (+)" [matTooltipPosition]="'after'">
            FINALIZADA
          </button>
          <button mat-raised-button disabled *ngIf="solicitacao.status == 4" class="disabled">
            EXPIRADA
          </button>
          <div matTooltip="Não foram encontrados XML's com os filtros informados." [matTooltipPosition]="'after'">
            <button mat-raised-button disabled *ngIf="solicitacao.status == 5" class="naoencontrado" >
              NÃO ENCONTRADO
            </button>
          </div>
          <button color="warn" matTooltip="Sua solicitação esta sendo processada. Em até 72h você terá uma resposta" mat-raised-button *ngIf="solicitacao.status == 6" >
            <span class="bold-disabled">PROCESSANDO</span>
          </button>
        </mat-cell>
      </ng-container>

      <!-- DATA DA SOLICITACAO Column -->
      <ng-container matColumnDef="dataSolicitacao">
        <mat-header-cell *matHeaderCellDef mat-sort-header> DATA SOLICITAÇÃO </mat-header-cell>
        <mat-cell *matCellDef="let solicitacao"> {{solicitacao.dataSolicitacao | date: 'dd/MM/yyyy'}} </mat-cell>
      </ng-container>

      <!-- DATA EXPIRAÇÃO Column -->
      <ng-container matColumnDef="dataExpiracao">
        <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="A data de expiração é contabilizada a partir da data de disponibilização." [matTooltipPosition]="'above'"> DATA DE EXPIRAÇÃO </mat-header-cell>
        <mat-cell *matCellDef="let solicitacao"
        [matTooltip]="solicitacao.dataDisponibilizacao ? 'Disponibilizado em: ' + (solicitacao.dataDisponibilizacao | date :'dd/MM/yyyy') : ''"
        [matTooltipPosition]="'before'">{{
          solicitacao.dataExpiracao ? (solicitacao.dataExpiracao | date : 'dd/MM/yyyy')
          : solicitacao.status == 5 ? 'INEXISTENTE'
          : 'PROCESSANDO'
        }}</mat-cell>
      </ng-container>

      <!-- AÇÕES Column -->
      <ng-container matColumnDef="acoes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>AÇÕES</mat-header-cell>
        <mat-cell *matCellDef="let solicitacao">
          <div class="acoes">
            <button class="action-button action-button-delete" mat-raised-button matTooltip="Deletar solicitação" (click)="openDeleteConfirmation(solicitacao.protocolo)"
              *ngIf="storage.getLocalUser()?.perfilDeUsuario == 'ADMIN'">
              <mat-icon>
                delete
              </mat-icon>
            </button>
            <button mat-raised-button matTooltip="Detalhes da solicitação" [matTooltipPosition]="'before'" (click)="detalhar(solicitacao.id)" class="action-button action-button-add">
              <mat-icon>
                add
              </mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
      <mat-row *matRowDef="let solicitacao; columns: displayColumns; let i = index"></mat-row>

      <!-- Row shown when there is no matching data. -->
      <mat-row *matNoDataRow>
        <!-- <mat-cell colspan="4">Nenhum dado Encontrado "{{input.value}}"</mat-cell> -->
      </mat-row>
    </mat-table>

    <!-- PAGINAÇÃO -->
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users">
    </mat-paginator>
  <!-- </div> -->
</div>
<div class="card-container mat-elevation-z4">
  <div class="header-card">
    <div class="buttons-card">
      <button mat-mini-fab class="detail" (click)="voltar()">
        <mat-icon>arrow_back_ios</mat-icon>
        <!-- voltar -->
      </button>

      <button mat-mini-fab class="detail" (click)="findAll()">
        <mat-icon>
          autorenew
        </mat-icon>
      </button>

      <button mat-raised-button color="primary" (click)="newSolicitacao()" class="new">
        Nova solicitação
      </button>
    </div>
    <mat-form-field appearance="outline" class="filter">
      <mat-label>Buscar</mat-label>
      <input matInput placeholder="Busca de solicitação" #input [(ngModel)]="filter">
    </mat-form-field>
  </div>
  <mat-card appearance="outlined" *ngFor="let solicitacao of solicitacoes | filter: filter">
    <mat-card-title-group>
      <mat-card-title>
        PROTOCOLO: {{solicitacao.protocolo}}
      </mat-card-title>
      <mat-card-subtitle>
        Data Solicitacão: {{dateFromString(solicitacao.dataSolicitacao) | date:'dd/MM/yyyy'}}
      </mat-card-subtitle>
      <button mat-raised-button disabled *ngIf="solicitacao.status == 1" class="pendente">
        PENDENTE
      </button>
      <a href="{{solicitacao.urlArquivo}}">
        <button mat-raised-button *ngIf="solicitacao.status == 2" class="download" (click)="findAll()">
          DISPONIVEL
        </button>
      </a>
      <button mat-raised-button color="warn" *ngIf="solicitacao.status == 3" class="pendente">
        FINALIZADA
      </button>
    </mat-card-title-group>
    <div class="acoes">
      <button mat-raised-button color="warn" *ngIf="storage.getLocalUser()?.perfilDeUsuario == 'ADMIN'">
        <mat-icon>
          delete
        </mat-icon>
      </button>
      <button mat-raised-button (click)="detalhar(solicitacao.id)" class="detail">
        <mat-icon>
          add
        </mat-icon>
      </button>
    </div>
    <mat-card-content>
    </mat-card-content>
  </mat-card>
</div>
