import { ConfiguracoesComponent } from './configuracoes.component';

import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: '',
    component: ConfiguracoesComponent,
  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })

export class ConfiguracoesRoutingModule { }
