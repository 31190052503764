import { STORAGE_KEYS } from '../config/storage_key.config';
import { LocalUser } from '../models/local_user'
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getLocalUser(): LocalUser | null {
    let user = localStorage.getItem(STORAGE_KEYS.localUser);
    if (user == null) {
      return null;
    } else {
      return JSON.parse(user);
    }
  }

  setLocalUser(user: LocalUser | null){
    if (user == null) { 
      localStorage.removeItem(STORAGE_KEYS.localUser);
    } else {     
      localStorage.setItem(STORAGE_KEYS.localUser, JSON.stringify(user));
    }
  }
  getUserProfile(): string | undefined {
    return this.getLocalUser()?.perfilDeUsuario
  }

}
