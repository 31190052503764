<div class="edit">
  <h2>Usuário</h2>
  <form>
    <mat-form-field *ngIf="usuario.id != ''" appearance="outline">
      <input matInput type="text" name="Id" [(ngModel)]="usuario.id" disabled />
      <mat-label>ID</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input matInput type="text" name="nome" placeholder="Ex: Nome Usuário" [(ngModel)]="usuario.nome" required />
      <mat-label>Nome</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input matInput type="text" name="email" placeholder="Ex: seuemail@email.com" [(ngModel)]="usuario.email"
        required />
      <mat-label>Email</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Perfil de usuário</mat-label>
      <mat-select [(value)]="selectedValue" (selectionChange)="onChange()" required>
        <mat-option *ngFor="let option of selectValues" [value]="option.value" required>
          {{option.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="usuario.id">
      <input matInput type="text" name="senha" placeholder="Ex: Sua Senha" [(ngModel)]="usuario.senha"/>
      <mat-label>Senha (em branco não altera)</mat-label>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!usuario.id">
      <input matInput type="text" name="senha" placeholder="Ex: Sua Senha" [(ngModel)]="usuario.senha" required/>
      <mat-label>Senha</mat-label>
    </mat-form-field>

  </form>
  <button 
    mat-raised-button class="btn-confirm" 
    (click)="usuario.id == '' || usuario.id == null ? createUsuario() : updateUsuario()"
    [disabled]="verifyDisable()"
  >
    Salvar
  </button>

  <button mat-raised-button class="btn-cancel" (click)="cancel()">
    Cancelar
  </button>
</div>