<mat-card appearance="outlined" class="mat-elevation-z4">
  <h1 class="header-title">Configurações</h1>
  <mat-tab-group>
    <mat-tab label="Geral">
      <div class="container">
        <h2>Configurações Gerais</h2>
        <mat-form-field appearance="outline">
          <mat-label>Prazo para download</mat-label>
          <mat-select required="true" [(ngModel)]="parametroValidadeDownload.valor">
            <mat-option value="7">7 Dias</mat-option>
            <mat-option value="15">15 Dias</mat-option>
            <mat-option value="30">30 Dias</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Horário exclusão</mat-label>
          <input matInput type="text" placeholder="Apenas numeros" name="horario" mask="00" suffix="H" required [(ngModel)]="parametroHorarioExclusao.valor"/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input matInput type="text" placeholder="Apenas numeros" name="horario" mask="00" suffix="H" required [(ngModel)]="parametroHorarioEnvioLembrete.valor"/>
          <mat-label>Horário envio lembrete</mat-label>
        </mat-form-field>
        <button mat-raised-button class="btn-confirm" (click)="updateParametrosGerais()">
            Salvar
        </button>
      </div>
    </mat-tab>
    <mat-tab label="Email">
      <div class="container">
        <mat-grid-list cols="3" rowHeight="480px" >
          <mat-grid-tile>
            <div class="configuracoes-smtp">
              <h2>Configurações de Email</h2>
              <mat-form-field appearance="outline">
                <input matInput type="text" placeholder="Ex.: smtp.gmail.com" name="host" required [(ngModel)]="parametroEmail.host"/>
                <mat-label>Host</mat-label>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input matInput type="text" placeholder="Ex.: 465" name="porta" mask="000" required [(ngModel)]="parametroEmail.port"/>
                <mat-label>Porta</mat-label>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input matInput type="text" placeholder="Ex.: email@sgsistemas.com.br" name="email" required [(ngModel)]="parametroEmail.username"/>
                <mat-label>Usuário</mat-label>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input matInput type="password" placeholder="Ex.: senha" name="senha" required [(ngModel)]="parametroEmail.password"/>
                <mat-label>Senha</mat-label>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input matInput type="text" placeholder="Ex.: emailDeEnvio@sgsistemas.com.br" name="emailDeEnvio" required [(ngModel)]="parametroEmail.emailSender"/>
                <mat-label>Email de envio</mat-label>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="configuracoes-smtp">
              <h2>Email de recuperação de senha</h2>
              <mat-form-field appearance="outline">
                <input matInput type="text" name="tituloEmailSenha" required [(ngModel)]="parametroEmail.titleEmailSenha"/>
                <mat-label>Titulo do email</mat-label>
              </mat-form-field>
              <mat-form-field appearance="outline" >
                <textarea class="body-email" matInput type="text" name="corpoEmailSenha" maxlength="250" required [(ngModel)]="parametroEmail.textEmailSenha"></textarea>
                <mat-label>Corpo do email</mat-label>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="configuracoes-smtp">
              <h2>Email de download</h2>
              <mat-form-field appearance="outline">
                <input matInput type="text" name="tituloEmailDownload" required [(ngModel)]="parametroEmail.titleEmailDownload"/>
                <mat-label>Titulo do email</mat-label>
              </mat-form-field>
              <mat-form-field appearance="outline" >
                <textarea class="body-email" matInput type="text" name="corpoEmailDownload" maxlength="250" required [(ngModel)]="parametroEmail.textEmailLinkDownload"></textarea>
                <mat-label>Corpo do email</mat-label>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="configuracoes-smtp">
              <h2>Email de lembrete</h2>
              <mat-form-field appearance="outline">
                <input matInput type="text" name="tituloEmailLembrete" required [(ngModel)]="parametroEmail.titleEmailLembrete"/>
                <mat-label>Titulo do email</mat-label>
              </mat-form-field>
              <mat-form-field appearance="outline" >
                <textarea  (resize)="false" class="body-email" matInput type="text" name="corpoEmailLembrete"  maxlength="250" required [(ngModel)]="parametroEmail.textEmailLembrete"></textarea>
                <mat-label>Corpo do email</mat-label>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="configuracoes-smtp">
              <h2>Email de XML's não encontrados</h2>
              <mat-form-field appearance="outline">
                <input matInput type="text" name="tituloEmailXmlsNaoEncontrados" required [(ngModel)]="parametroEmail.titleEmailXmlsNaoEncontrados"/>
                <mat-label>Titulo do email</mat-label>
              </mat-form-field>
              <mat-form-field appearance="outline" >
                <textarea  (resize)="false" class="body-email" matInput type="text" name="corpoEmailXmlsNaoEncontrados"  maxlength="250" required [(ngModel)]="parametroEmail.textEmailXmlsNaoEncontrados"></textarea>
                <mat-label>Corpo do email</mat-label>
              </mat-form-field>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <button mat-raised-button class="btn-confirm" (click)="updateEmailParam()">
          Salvar
      </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
