import { ClienteService } from 'src/app/services/domain/cliente.service';
import { StorageService } from 'src/app/services/storage.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AlertModalComponent } from '../../../modals/alert-modal/alert.modal.component';
import { SolicitacaoService } from '../../../../services/domain/solicitacao.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from 'src/app/services/util.service';
import { Component, OnInit, Inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ContadorService } from 'src/app/services/domain/contador.service';
import { SolicitacaoNewDTO } from 'src/app/models/solicitacao.new.dto';

@Component({
  selector: 'app-solicitacao-form-multiple',
  templateUrl: './solicitacao-form-multiple.component.html',
  styleUrls: ['./solicitacao-form-multiple.component.scss']
})
export class SolicitacaoFormMultipleComponent implements OnInit {

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  maxDate: Date = new Date();

  solicitacao: SolicitacaoNewDTO = {
    cnpjCliente: '',
    cpfCnpjContadorSolicitante: '',
    periodoDe: '',
    periodoAte: '',
    numeroDocumentoInicio: 0,
    numeroDocumentoFim: 0,
    serieInicio: undefined,
    serieFim: undefined,
  };
  startDate = new Date();
  usuarioMaster: boolean = false;
  arrayCliente = new Array();
  clienteString: string;
  displayColumns!: string[];

  constructor(
    private utilService: UtilService,
    private dateAdapter: DateAdapter<any>,
    private dialogRef: MatDialogRef<SolicitacaoFormMultipleComponent>,
    @Inject(MAT_DIALOG_DATA) public newSolicitacaoMultiple: Array<SolicitacaoNewDTO>,
    private solicitacaoService: SolicitacaoService,
    private dialog: MatDialog,
    private contadorService: ContadorService,
    private storage: StorageService,
    private clienteService: ClienteService
  ) { }

  ngOnInit(): void {
    this.dateAdapter.setLocale('pt-BR');
    this.createArrayCliente();
    this.verificaPerfilUsuario();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  transformDate(date: Date): string {
    return date.toJSON().substring(0, 10)
  }

  async createMultiple(solicitacoes: Array<SolicitacaoNewDTO>) {
    if(this.validaPeriodo()) {
      var date1 = new Date(await this.transformDate(this.range.value.start));
      var date2 = new Date(await this.transformDate(this.range.value.end));
      var timeDiff = Math.abs(date2.getTime() - date1.getTime());
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if(date2 > new Date()) {
        this.openAlert('Data final maior do que data atual.');
        return;
      }

      if (diffDays >= 31) {
        this.openAlert('Período maior que o permitido (máximo 31 dias)');
        return;
      } else {
          solicitacoes.forEach(solicitacao => {
            solicitacao.periodoDe = this.transformDate(this.range.value.start);
            solicitacao.periodoAte = this.transformDate(this.range.value.end);
            solicitacao.numeroDocumentoInicio = 1;
            solicitacao.numeroDocumentoFim = 999999999;
            solicitacao.serieInicio = 1;
            solicitacao.serieFim = 999;
          })
          return this.solicitacaoService.createMultiple(solicitacoes).subscribe(
            res => {
              this.utilService.showMessage("Sua solicitação foi recebida. \nAssim que disponivel será enviado um email com o link para download. \nSua solicitação pode levar até 72horas para ser processada.", 'snack-bar-success', 5000, "bottom")
              this.onNoClick();
            },
            e => {
              this.openAlert(`Não Foi possivel Enviar a Solicitacao. \n ${e.error.message}`)
            }
          )
      }
    } else {
      return;
    }
  }

  openAlert(mensagem: string) {
    this.dialog.open(
      AlertModalComponent, {
      width: (window.screen.width > 900) ? '30%' : '60%',
      data: mensagem
    }
    )
  }

  validaCpfCnpjCliente() {
    this.newSolicitacaoMultiple.forEach(async cnpj => {
      await this.clienteService.findByCnpj(cnpj.cnpjCliente).subscribe(
        res => {
      }, e => {
        this.openAlert('CPF/CNPJ do cliente não foi localizado na base de dados, tente outro.');
      })
    })
  }

  validaCpfCnpjSolicitante() {
    this.contadorService.findByCpfCnpj(this.newSolicitacaoMultiple[0].cpfCnpjContadorSolicitante).subscribe(
      res => {
      }, e => {
        this.openAlert('CPF/CNPJ do solicitante não foi localizado na base de dados, tente outro.');
      }
    )
  }

  async verificaPerfilUsuario() {
    if(this.storage.getLocalUser()?.perfilDeUsuario == "ADMIN") {
      this.usuarioMaster = true;
    } else {
      this.usuarioMaster = false;
    }
  }

  validaPeriodo(): boolean {
    if(this.range.value.start == "" || this.range.value.end == "" || this.range.value.start == null || this.range.value.end == null) {
      this.openAlert('Período desejado não definido.');
      return false;
    }
    return true;
  }

  createArrayCliente() {
    this.arrayCliente = new Array();
    this.newSolicitacaoMultiple.forEach(cliente => {
      this.arrayCliente.push(`${cliente.nome} (${cliente.cnpjCliente})`);
    })
    this.clienteString = this.arrayCliente.join("\n\n");
  }

  rowCalculation(): number {
    let rows = this.arrayCliente.length * 2;
    if (rows >= 10) {
      return 9;
    } else {
      return rows - 1;
    }
  }
}
