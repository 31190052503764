<div class="container">
  <div class="content mat-elevation-z4">
    <div class="logo" *ngIf="screen() > 900">
      <img src="../../../../assets/logoLgin.png" alt="logo login" width="200" height="133">
    </div>
    <span class="line " *ngIf="screen() > 900"></span>
    <div class="login " *ngIf="registered">
      <img src="../../../../assets/logoLgin.png" alt="logo login" *ngIf="screen() < 900" class="logo-login" width="200" height="133">
      <mat-form-field appearance="outline">
        <mat-label>Seu email</mat-label>
        <input required name="email" onfocus="this.select()" [(ngModel)]="credenciais.email"
          placeholder="seuemail@email.com" matInput>
        <mat-icon mat-icon matSuffix>
          mail
        </mat-icon>
        <mat-progress-bar mode="indeterminate" *ngIf="enviando"></mat-progress-bar>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="!forgotPassword">
        <mat-label>Sua senha</mat-label>
        <input required name="senha" onfocus="this.select()" [(ngModel)]="credenciais.senha" matInput
          [type]="hide == 'password' ? 'password' : 'text'" placeholder="sua senha" (keyup.enter)="login()">
        <button class="icon" mat-icon-button matSuffix (click)="passwordVisibility()">
          <mat-icon>
            {{hide == 'password' ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </button>
      </mat-form-field>

      <label style="margin-bottom: 1rem;" *ngIf="!forgotPassword">
        <input type="checkbox" [(ngModel)]="this._lembrarMe" [ngModelOptions]="{standalone: true}" (click)="setLembrarSenha();">
        <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>
        <span class="text-inverse">Lembrar senha</span>
      </label>

      <button class="submit" mat-raised-button (click)="(!forgotPassword) ? login() : resetPassword()">
        {{!forgotPassword ? 'ENTRAR' : 'ENVIAR EMAIL'}} </button>
      <button *ngIf="forgotPassword" mat-raised-button class="cadastrar"
        (click)="forgotPassword = !forgotPassword; enviando = false">
        CANCELAR
      </button>
      <button *ngIf="!forgotPassword" mat-raised-button (click)="onClickSignUp()" class="cadastrar">
        CADASTRAR
      </button>
      <p *ngIf="!forgotPassword" (click)="forgotPassword = !forgotPassword" style="text-align: center;">
        Esqueci a senha!
      </p>
    </div>

    <!--Cadastro de contadores-->
    <div class="sign-up" *ngIf="!registered">
      <h1>Cadastro</h1>
      <mat-form-field appearance="outline">
        <mat-label>CPF/CNPJ</mat-label>
        <input required name="cpfCnpj" onfocus="this.select()" [(ngModel)]="contador.cpfCnpj"
          (blur)="findContadorByCpfCnpj(contador.cpfCnpj)" placeholder="Informe o CPF ou CNPJ"
          mask='00.000.000/0000-00||000.000.000-00' matInput>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Nome</mat-label>
        <input required name="nome" onfocus="this.select()" [(ngModel)]="contador.nome" placeholder="Ex.: João Da Silva"
          matInput>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Contabilidade</mat-label>
        <input required name="contabi" onfocus="this.select()" [(ngModel)]="contador.contabilidade"
          placeholder="Ex.: Contabilidade LTDA" matInput>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Seu email</mat-label>
        <input required name="email" onfocus="this.select()" [(ngModel)]="contador.email"
          placeholder="seuemail@email.com" matInput>
        <mat-icon mat-icon matSuffix>
          mail
        </mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="!forgotPassword">
        <mat-label>Sua senha</mat-label>
        <input required name="senha" onfocus="this.select()" [(ngModel)]="contador.senha" matInput
          [type]="hide == 'password' ? 'password' : 'text'" placeholder="sua senha" (keyup.enter)="login()">
        <button class="icon" mat-icon-button matSuffix (click)="passwordVisibility()">
          <mat-icon>
            {{hide == 'password' ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </button>
      </mat-form-field>

      <button class="submit" mat-raised-button (click)="signUp()">
        CADASTRAR
      </button>
      <button mat-raised-button (click)="onClickSignUp()" class="cadastrar">
        VOLTAR
      </button>
    </div>
  </div>
</div>

<div *ngIf="showModalCookie" class="cookie-box">
  <div>Nós utilizamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços. Ao utilizar nossos serviços, você concorda com nossos <a target="_black" href="https://sgsistemas.com.br/termos-de-uso/">termos de uso</a> e nossas <a target="_black" href="https://sgsistemas.com.br/politicas-de-privacidade/">políticas de privacidade</a>.</div>
  <button (click)="this.purecookieDismiss()">CONCORDO</button>
</div>
