import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt, 'pt-BR');


@Injectable({
  providedIn: 'root'
})
export class LocaleConfig {

  getLocale(): string {
    return 'pt-BR';
  }

  constructor() { }
}
