import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SolicitacaoRoutingModule } from './solicitacao.routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolicitacaoFormComponent } from './solicitacao-form/solicitacao-form.component';
import { SolicitacaoComponent } from './solicitacao.component';
import { SolicitacaoDetalheComponent } from './solicitacao-detalhe/solicitacao-detalhe.component';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SolicitacaoFormMultipleComponent } from './solicitacao-form-multiple/solicitacao-form-multiple.component';
import { FilterPipeModule } from 'src/app/pipes/filter/filter.module';


@NgModule({
  declarations: [
    SolicitacaoFormComponent,
    SolicitacaoComponent,
    SolicitacaoDetalheComponent,
    SolicitacaoFormMultipleComponent
  ],
  imports: [
    CommonModule,
    SolicitacaoRoutingModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    NgxMaskModule,
    MatDatepickerModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule, 
    MatCardModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FilterPipeModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ]
})
export class SolicitacaoModule { }
