import { DeleteComfirmationComponent } from './../../modals/delete-comfirmation/delete-comfirmation.component';
import { ContadorFormComponent } from './contador-form/contador-form.component';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from './../../../services/storage.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ContadorDTO } from 'src/app/models/contador.dto';
import { ContadorService } from 'src/app/services/domain/contador.service';
import { UtilService } from 'src/app/services/util.service';
import { ContadorVincularClienteComponent } from './contador-vincular-cliente/contador-vincular-cliente.component';

@Component({
  selector: 'app-contador',
  templateUrl: './contador.component.html',
  styleUrls: ['./contador.component.scss']
})
export class ContadorComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public filter: string = '';
  public deleteConfirmed: boolean = false;
  public dataSource: MatTableDataSource<ContadorDTO>;
  public contadores: ContadorDTO[] = [];
  public displayColumns = ['nome', 'cpf', 'contabilidade', 'email', 'dataCadastro','acoes'];

  constructor(
    private contadorService: ContadorService,
    public storage: StorageService,
    private dialog: MatDialog,
    private utilService: UtilService) { }

  async ngOnInit() {
    await this.findAll()
  }

  public async findAll() {
    return (await this.contadorService.findAll())
      .subscribe(
        contadores => {
          contadores.map( contador => {
            if (contador.dataCadastro != undefined){
              contador.dataCadastro = new Date(contador.dataCadastro).toLocaleDateString("pt-BR");
            }
          })
          this.contadores = contadores;
          this.setDataSource()
        }
      )
  }

  public del(id: string) {
    this.contadorService.delete(id)
      .subscribe(
        response => {
          this.utilService.showMessage('contador deletado com sucesso!', 'snack-bar-success', null, "top");
          this.findAll();
        },
        e => this.utilService.showMessage(e.error.message, 'snack-bar-error', 5000, "top")

      )
  }
  public openDeleteConfirmation(contador: ContadorDTO) {
    let mensagem: string = `Confirma a exclusão do contador: \n${contador.nome}`
    const dialogRef = this.dialog.open(DeleteComfirmationComponent, {
      width: '400px',
      data: { mensagem: mensagem, confirmed: this.deleteConfirmed },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.del(contador.id!)
    });
  }
  public setDataSource() {
    this.dataSource = new MatTableDataSource(this.contadores);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public openContadorForm(id: string | null) {
    const dialogRef = this.dialog.open(
      ContadorFormComponent,
      {
        width: '400px',
        data: id
      }
    )
    dialogRef.afterClosed().subscribe(result => {
      this.findAll();
    });
  }
  public openBindClient(cnpjContador: string, nomeContador: string){
    const dialogRef = this.dialog.open(
      ContadorVincularClienteComponent,{
        width: '500px',
        data: {
          cnpj: cnpjContador,
          nome: nomeContador
        }
      }
    )
  }

}
