import { SolicitacaoService } from './../../../services/domain/solicitacao.service';
import { SolicitacaoNewDTO } from './../../../models/solicitacao.new.dto';
import { ClienteFormComponent } from './cliente-form/cliente-form.component';
import { DeleteComfirmationComponent } from './../../../components/modals/delete-comfirmation/delete-comfirmation.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { StorageService } from '../../../services/storage.service';
import { ClienteDTO } from '../../../models/cliente.dto';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ClienteService } from 'src/app/services/domain/cliente.service';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from 'src/app/services/util.service';
import { SolicitacaoFormMultipleComponent } from '../solicitacao/solicitacao-form-multiple/solicitacao-form-multiple.component';

@Component({
  selector: 'app-mercado',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit {

  filter: string = '';
  deleteConfirmed: boolean;
  clientes: ClienteDTO[] = [];
  displayColumns: string[] = [];
  dataSource: MatTableDataSource<ClienteDTO>;
  cnpjArray = new Array();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  arraySolicitacaoDTO = new Array<SolicitacaoNewDTO>();

  constructor(
    private clienteService: ClienteService,
    private dialog: MatDialog,
    private utilService: UtilService,
    public storage: StorageService,
    private solicitacaoService: SolicitacaoService
  ) { }

  ngOnInit(): void {
    this.findAll();
    this.setDisplayColumns();
  }
  public openDeleteConfirmation(cliente: ClienteDTO) {
    let mensagem: string = `Confirma a exclusão do cliente: \n${cliente.nome}`
    const dialogRef = this.dialog.open(DeleteComfirmationComponent, {
      width: '40em',
      height: '30em',
      data: { mensagem: mensagem, confirmed: this.deleteConfirmed },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.del(cliente.id!)
    });
  }

  public findAll() {
    window.setTimeout(
      () => {
        this.clienteService.findAll()
          .subscribe(
            clientes => {
              clientes.map( async cliente => {
                  cliente.download = await this.countDownloadStatus(cliente.cnpj)
                  if (cliente.dataCadastro != undefined){
                    cliente.dataCadastro = new Date(cliente.dataCadastro).toLocaleDateString("pt-BR");
                  }
              })
              this.clientes = clientes
              this.setDataSource()
            }
          );
      }, 300
    );
  }
  public async countDownloadStatus(cnpj: string | undefined): Promise<number>{
    const cpfcnpj = this.storage.getLocalUser()?.cpfCnpj!;
    let download = 0
    if(cpfcnpj) {
      await this.solicitacaoService.findByCnpj(cnpj!).toPromise().then(solicitacoes => {
        download =  solicitacoes.filter(solicitacao => solicitacao.status == 2 && solicitacao.cpfCnpjContadorSolicitante == cpfcnpj).length
      })
    }
    return download;
  }
  public del(id: string) {
    this.clienteService.delete(id)
      .subscribe(
        response => {
          this.utilService.showMessage('Cliente excluido com sucesso', 'snack-bar-success', null, "top");
          this.findAll();
          this.deleteConfirmed = false;
        },
        error => {
          this.utilService.showMessage("Falha na autenticação Não Foi possivel fazer a exclusão", 'snack-bar-error', 5000, "top");
        }
      )
  }
  public setDataSource() {
    this.dataSource = new MatTableDataSource(this.clientes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter
  }
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public openClienteForm(id: string | null) {
    const dialogRef = this.dialog.open(
      ClienteFormComponent,
      {
        width: '400px',
        data: id
      }
    )
    dialogRef.afterClosed().subscribe(result => {
      this.findAll();
    });
  }
  // --------------------------------------------------------------NOVO------------------------------------------------------------------
  setAll(checked: boolean) {
    this.clearArray();
    this.dataSource.filteredData.forEach(cliente => {
      if(checked) {
        cliente.checked = true;
      } else {
        cliente.checked = false;
      }
    })
  }

  onCheck(index: number) {

    const cliente = this.dataSource.filteredData[index];

    cliente.checked = !cliente.checked

  }

  clearArray() {
    this.arraySolicitacaoDTO = []
  }

  newMultipleSolicitacao() {
    this.dataSource.data
      .filter((cliente) => cliente.checked)
      .forEach((cliente) => {
        let newSolicitacao: SolicitacaoNewDTO = {
          cnpjCliente: '',
          cpfCnpjContadorSolicitante: '',
          periodoDe: '',
          periodoAte: '',
          nome: '',
        };
        newSolicitacao.nome = cliente.nome;
        newSolicitacao.cnpjCliente = cliente.cnpj!;
        newSolicitacao.cpfCnpjContadorSolicitante =
          this.storage.getLocalUser()?.cpfCnpj!;
        this.arraySolicitacaoDTO.push(newSolicitacao);


      });
    const dialogRef = this.dialog.open(
      SolicitacaoFormMultipleComponent,
      {
        data: this.arraySolicitacaoDTO
      }
    )
    dialogRef.afterClosed().subscribe(async close => {
      this.clearArray();
    })
  }

  isAllChecked() {
    return this.dataSource && this.dataSource.filteredData.length > 0 && this.dataSource.filteredData.every(x => x.checked)
  }

  clearCheckbox() {
    this.dataSource.data.forEach(row => {
      row.checked = false;
    })
  }

  shouldEnableSolicitacaoButtom() {
    return this.dataSource && this.dataSource.data.filter(x => x.checked).length > 1
  }

  setDisplayColumns() {
    if(this.storage.getUserProfile() == 'ADMIN' || this.storage.getUserProfile() == 'SUPORTE') {
      this.displayColumns = ['checkbox', 'nome', 'cpfCnpj', 'telefone', 'responsavel', 'dataCadastro', 'acoes'];
    } else {
      this.displayColumns = ['checkbox', 'nome', 'cpfCnpj', 'telefone', 'responsavel', 'acoes'];
    }
  }
}
