import { StorageService } from 'src/app/services/storage.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AlertModalComponent } from './../../../modals/alert-modal/alert.modal.component';
import { SolicitacaoService } from './../../../../services/domain/solicitacao.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from 'src/app/services/util.service';
import { SolicitacaoNewDTO } from '../../../../models/solicitacao.new.dto';
import { Component, OnInit, Inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ContadorService } from 'src/app/services/domain/contador.service';
import { ClienteService } from 'src/app/services/domain/cliente.service';

@Component({
  selector: 'app-solicitacao-form',
  templateUrl: './solicitacao-form.component.html',
  styleUrls: ['./solicitacao-form.component.scss']
})
export class SolicitacaoFormComponent implements OnInit {
  maxDate: Date = new Date();

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });


  solicitacao: SolicitacaoNewDTO = {
    cnpjCliente: '',
    cpfCnpjContadorSolicitante: '',
    periodoDe: '',
    periodoAte: '',
    numeroDocumentoInicio: 0,
    numeroDocumentoFim: 0,
    serieInicio: undefined,
    serieFim: undefined,
  };
  startDate = new Date();
  usuarioMaster: boolean = false;

  constructor(
    private utilService: UtilService,
    private dateAdapter: DateAdapter<any>,
    private dialogRef: MatDialogRef<SolicitacaoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public newSolicitacao: SolicitacaoNewDTO,
    private solicitacaoService: SolicitacaoService,
    private dialog: MatDialog,
    private contadorService: ContadorService,
    private clienteService: ClienteService,
    private storage: StorageService
  ) {
  }

  ngOnInit(): void {
    this.dateAdapter.setLocale('pt-BR');
    this.solicitacao = this.newSolicitacao;
    this.verificaPerfilUsuario();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  transformDate(date: Date): string {
    return date.toJSON().substring(0, 10)
  }

  async create(solicitacao: SolicitacaoNewDTO) {
    if(this.validaPeriodo()) {
      var date1 = new Date(await this.transformDate(this.range.value.start));
      var date2 = new Date(await this.transformDate(this.range.value.end));
      var timeDiff = Math.abs(date2.getTime() - date1.getTime());
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if(date2 > new Date()) {
        this.openAlert('Data final maior do que data atual.');
        return;
      }

      if (diffDays >= 31) {
        this.openAlert('Período maior que o permitido (máximo 31 dias)');
        return;
      } else {
        let numeroDocumentoInicio = solicitacao.numeroDocumentoInicio ? solicitacao.numeroDocumentoInicio : 0;
        let numeroDocumentoFim = solicitacao.numeroDocumentoFim ? solicitacao.numeroDocumentoFim : 0;
        let serieInicio = solicitacao.serieInicio ? solicitacao.serieInicio : 0;
        let serieFim = solicitacao.serieFim ? solicitacao.serieFim : 0;

        if (Number(numeroDocumentoInicio) > Number(numeroDocumentoFim)) {
          this.openAlert('Número do documento inicial maior que o número do documento final 1.');
          return;
        } else if (Number(serieInicio) > Number(serieFim)) {
          this.openAlert('Número de serie inicial maior que o número de serie final.');
          return;
        } else {
          solicitacao.periodoDe = await this.transformDate(this.range.value.start);
          solicitacao.periodoAte = await this.transformDate(this.range.value.end);

          if (typeof solicitacao.numeroDocumentoInicio == "undefined" || solicitacao.numeroDocumentoInicio == null || solicitacao.numeroDocumentoInicio == 0) {
            solicitacao.numeroDocumentoInicio = 1;
          }
          if (typeof solicitacao.numeroDocumentoFim == "undefined" || solicitacao.numeroDocumentoFim == null || solicitacao.numeroDocumentoFim == 0) {
            solicitacao.numeroDocumentoFim = 999999999;
          }
          if (typeof solicitacao.serieInicio == "undefined" || solicitacao.serieInicio == null || solicitacao.serieInicio == 0) {
            solicitacao.serieInicio = 1;
          }
          if (typeof solicitacao.serieFim == "undefined" || solicitacao.serieFim == null || solicitacao.serieFim == 0) {
            solicitacao.serieFim = 999;
          }
          return await this.solicitacaoService.create(solicitacao).subscribe(
            () => {
              this.utilService.showMessage("Sua solicitação foi recebida. \nAssim que disponivel será enviado um email com o link para download. \n Sua solicitação pode levar até 72horas para ser processada.", 'snack-bar-success', 5000, "bottom")
              this.solicitacao.numeroDocumentoInicio = undefined;
              this.solicitacao.numeroDocumentoFim = undefined;
              this.solicitacao.serieInicio = undefined;
              this.solicitacao.serieFim = undefined;
              this.onNoClick();
            },
            e => {
              this.openAlert(`Não Foi possivel Enviar a Solicitacao. \n ${e.error.message}`)
            }
          )
        }
      }
    } else {
      return;
    }

  }

  openAlert(mensagem: string) {
    this.dialog.open(
      AlertModalComponent, {
      width: (window.screen.width > 900) ? '30%' : '60%',
      data: mensagem
    }
    )
  }

  validaCpfCnpjCliente() {
    this.clienteService.findByCnpj(this.solicitacao.cnpjCliente).subscribe(
      res => {
      }, e => {
        this.openAlert('CPF/CNPJ do cliente não foi localizado na base de dados, tente outro.');
      }
    )
  }

  validaCpfCnpjSolicitante() {
    this.contadorService.findByCpfCnpj(this.solicitacao.cpfCnpjContadorSolicitante).subscribe(
      res => {
      }, e => {
        this.openAlert('CPF/CNPJ do solicitante não foi localizado na base de dados, tente outro.');
      }
    )
  }

  validaNumeroDocumento() {
    let numeroDocumentoInicio = this.solicitacao.numeroDocumentoInicio ? this.solicitacao.numeroDocumentoInicio : 0;
    let numeroDocumentoFim = this.solicitacao.numeroDocumentoFim ? this.solicitacao.numeroDocumentoFim : 0;

    if (Number(numeroDocumentoFim) < Number(numeroDocumentoInicio)) {
      this.openAlert('Número do documento inicial maior que o número do documento final.');
    }
  }

  validaNumeroSerie() {
    let serieInicio = this.solicitacao.serieInicio ? this.solicitacao.serieInicio : 0;
    let serieFim = this.solicitacao.serieFim ? this.solicitacao.serieFim : 0;

    if (Number(serieFim) < Number(serieInicio)) {
      this.openAlert('Número de serie inicial maior que o número de serie final.');
    }
  }

  async verificaPerfilUsuario() {
    if(this.storage.getLocalUser()?.perfilDeUsuario == "ADMIN") {
      this.usuarioMaster = true;
    } else {
      this.usuarioMaster = false;
    }
  }

  numericOnly(event: { which: any; keyCode: any; }): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  validaPeriodo(): boolean {
    if(this.range.value.start == "" || this.range.value.end == "" || this.range.value.start == null || this.range.value.end == null) {
      this.openAlert('Período desejado não definido.');
      return false;
    }
    return true;
  }
}
